import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'

const enterTheExamRouter = {
  path: '/enterTheExam',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '',
    icon: 'startTheExam',
    roles: [USER_TYPE.student.val],
  },
  // alwaysShow: true,
  children: [
    {
      path: '',
      component: () => import('@/views/student/enterTheExam/index'),
      name: 'enterTheExam',
      meta: { title: '进入考试', icon: null },
    },
  ],
}

export default enterTheExamRouter
