<template>
  <div class="app-container">
    <div v-if="examSessions" class="wrap flex align-center justify-center">
      <img src="../../../assets/images/no-tests.png">
    </div>
  </div>
</template>

<script>

import { getSessionsInfo } from '@/api/student/exam'
import { getToken } from '@/utils/auth'

export default {
  name: 'EnterTheExam',
  components: {},
  data() {
    return {
      examSessions: false,
    }
  },
  created() {
    this.getExamSessions()
  },
  methods: {
    getExamSessions() {
      getSessionsInfo().then(res => {
        if (!res.data.length) {
          this.examSessions = true
        } else {
          this.getExam()
        }
      })
    },
    getExam() {
      window.open(process.env.VUE_APP_EXAM_END + '/#/sso/login/' + getToken())
    },
  },
}
</script>
<style lang="css" scoped>
.wrap {
  height: calc(100vh - 154px);
  margin: 12px;
  background-color: white;
}
</style>
