// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ces-table-require::before {\n  content: '*';\n  color: red;\n}\n.pagination-style .el-input--medium .el-input__inner, .pagination-style .el-input--mini .el-input__inner {\n  height: 30px;\n  line-height: 30px;\n}\n.ces-table {\n  /*min-height: calc(150px - 50px);*/\n  /*max-height: calc(100% - 50px);*/\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.ces-table1 {\n  /*min-height: 100px;*/\n  /*max-height: 100%;*/\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n}\n.newComponents .el-table--scrollable-y .el-table__body-wrapper {\n  /* max-height: 100% !important; */\n  overflow: auto;\n}\n.select .el-select-dropdown__item.selected {\n  color: #C03639;\n}\n.el-input--medium .el-input__inner, .el-input--mini .el-input__inner {\n  height: 40px;\n  line-height: 40px;\n  border-radius: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"menuText": "#B0C5E3",
	"menuActiveText": "#1082E3",
	"subMenuActiveText": "#1082E3",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#1082E3",
	"sideBarWidth": "210px",
	"bigScreenBackground": "#04093e",
	"bigScreenBorderPrimary": "#3185d3",
	"bigScreenBorderPrimarySimple": "#24BBF5",
	"bigScreenBorderSecond": "#2e6eb3",
	"bigScreenBorderTitle": "#00e4ff"
};
module.exports = exports;
