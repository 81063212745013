// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#B0C5E3",
	"menuActiveText": "#1082E3",
	"subMenuActiveText": "#1082E3",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#1082E3",
	"sideBarWidth": "210px",
	"bigScreenBackground": "#04093e",
	"bigScreenBorderPrimary": "#3185d3",
	"bigScreenBorderPrimarySimple": "#24BBF5",
	"bigScreenBorderSecond": "#2e6eb3",
	"bigScreenBorderTitle": "#00e4ff"
};
module.exports = exports;
