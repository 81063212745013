// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/tel.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/site.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "footer[data-v-74fb9222] {\n  background: #595656;\n  color: #fff;\n}\nfooter .footer-content[data-v-74fb9222] {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    padding: 20px 100px;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\nfooter .left-part[data-v-74fb9222] {\n    padding: 0 60px 12px 0;\n    margin-left: 100px;\n    border-right: 1px solid #fff;\n    margin-bottom: 10px;\n}\nfooter .left-part .logo-img[data-v-74fb9222] {\n      display: block;\n      width: 78px;\n      margin-top: 65px;\n      -webkit-box-sizing: border-box;\n              box-sizing: border-box;\n}\nfooter .copyright[data-v-74fb9222] {\n    padding: 20px 0 20px 52px;\n}\nfooter .copyright p[data-v-74fb9222] {\n      display: -webkit-box;\n      display: -ms-flexbox;\n      display: flex;\n      -webkit-box-align: center;\n          -ms-flex-align: center;\n              align-items: center;\n      font-size: 18px;\n}\nfooter .copyright .tel_icon[data-v-74fb9222] {\n      display: inline-block;\n      width: 30px;\n      height: 30px;\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 0 0 no-repeat;\n      margin-right: 10px;\n}\nfooter .copyright .site_icon[data-v-74fb9222] {\n      display: inline-block;\n      width: 30px;\n      height: 30px;\n      background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 0 0 no-repeat;\n      margin-right: 10px;\n}\n", ""]);
// Exports
module.exports = exports;
