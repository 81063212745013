var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _c("div", { staticClass: "newLogo" }, [
        _c(
          "div",
          { staticClass: "flex flex-row  align-center justify-between" },
          [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "pr16 flex flex-row align-center" },
              [
                _c("span", { staticClass: "c-AFC5E3 fs20 pr20" }, [
                  _vm._v(_vm._s(_vm.nowDateTime)),
                ]),
                _c("img", {
                  staticClass: "pr20 pointer",
                  attrs: { src: require("../assets/images/enlarge.png") },
                  on: {
                    click: function ($event) {
                      return _vm.enlargeClick()
                    },
                  },
                }),
                _c(
                  "el-dropdown",
                  [
                    _c("div", { staticClass: "el-dropdown-link" }, [
                      _c("img", {
                        staticClass: "pointer",
                        attrs: { src: require("../assets/images/user.png") },
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      _vm._l(_vm.menusInfo, function (item, index) {
                        return _c("el-dropdown-item", { key: index }, [
                          _vm._v(" " + _vm._s(item.title) + " "),
                        ])
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("navbar"), _vm.needTagsView ? _c("tags-view") : _vm._e()],
            1
          ),
          _c("app-main"),
          _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "font-size": "0" } }, [
      _c("img", { attrs: { src: require("../assets/images/top-wz.png") } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }