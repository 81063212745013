import request from '@/utils/request'

// 机构端 学员管理 编辑按钮 获取学员详细信息
export function getStuInfo(stuId) {
  return request({
    url: '/student/getStudentByIds',
    method: 'get',
    params: {
      stuId,
    },
  })
}

// 机构端 学习管理 学习详情
export function getOrgRecordDetails(courseId, enrollId) {
  return request({
    url: `/record/orgRecordDetails`,
    method: 'get',
    params: {
      courseId,
      enrollId,
    },
  })
}

