import axios from 'axios'
import { MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import { generateJwtToken } from '@/utils/jwt'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000, // request timeout
})

// let loadingInstance = null
// function startLoading() {
//   loadingInstance = Loading.service({
//     fullscreen: true,
//     text: '拼命加载中...',
//     background: 'rgba(0, 0, 0, 1)',
//   })
// }
// function endLoading() {
//   loadingInstance.close()
// }
// let needLoadingRequestCount = 0
// function showFullScreenLoading() {
//   if (needLoadingRequestCount === 0) {
//     startLoading()
//   }
//   needLoadingRequestCount++
// }
// function tryHideFullScreenLoading() {
//   if (needLoadingRequestCount <= 0) return
//   needLoadingRequestCount--
//   if (needLoadingRequestCount === 0) {
//     endLoading()
//   }
// }

// request interceptor
service.interceptors.request.use(
  config => {
    // showFullScreenLoading()
    // do something before request is sent

    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = getToken()
    // }
    // return config

    let token = getToken()
    if (!token) {
      token = 'JWT ' + generateJwtToken()
    } else {
      token = 'Bearer ' + token
    }
    config.headers['Authorization'] = token
    config.headers['source'] = process.env.VUE_APP_SOURCE
    // const token = sessionStorage.getItem('token')
    // config.headers.Authorization = 'Bearer ' + token
    return config
  },
  error => {
    // tryHideFullScreenLoading()
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // tryHideFullScreenLoading()
    if (response.request.responseType === 'arraybuffer') {
      // 返回文件流，则直接返回
      return response.data
    }
    const res = response.data
    if (res.code === '401') {
      store.dispatch('user/resetToken').then(() => {
        location.reload()
      })
    }

    // 忽略错误提示处理，则直接将接口的返回值返回，交由调用方处理
    if (response.config.ignoreErrmsg && response.config.ignoreErrmsg === true) {
      return res
    }
    // if the custom code is not 20000, it is judged as an error.
    if (!res.success) {
      Message({
        message: res.msg || '操作失败',
        type: 'error',
        duration: 5 * 1000,
      })
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  error => {
    // tryHideFullScreenLoading()
    console.log('err' + error) // for debug
    Message({
      message: '服务不可用，请重试',
      type: 'error',
      duration: 5 * 1000,
    })
    return Promise.reject(error)
  },
)

export default service
