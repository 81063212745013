<template>
  <div class="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <HTable
      :is-handle="true"
      :is-index="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-handles="tableHandles"
      :table-page="tablePage"
      :that="that"
    />
    <AddPlanDialog
      :flag-visible.sync="dialogShow"
      :plan-info="planInfo"
      :title="Title"
    />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch'
import HTable from '@/components/MyTable'
import AddPlanDialog from '../components/addPlanDialog.vue'
import Tools from '@/utils/tools'
import { examStatus, PLAN_STATES, USER_TYPE } from '@/const/sys'
import { getUserInfo } from '@/utils/auth'
import { examList } from '@/api/company/examPlan'
import { mapMutations } from 'vuex'

export default {
  name: 'ExamPlanList',
  components: {
    HSearch,
    HTable,
    AddPlanDialog,
  },
  data() {
    const _EXAM = Tools.mapToSelctList(examStatus)
    return {
      userInfo: {},
      PLAN_STATES,
      USER_TYPE,
      that: this,
      tableLoading: false,
      dialogShow: false,
      Title: '新增考试计划',
      planInfo: {},
      searchData: {
        examSessionsName: '',
        examSerial: '',
        examStatus: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '计划名称',
          width: '260px',
          prop: 'examSessionsName',
          placeholder: '请输入计划名称',
          isClearable: true,
        },
        {
          type: 'input',
          label: '计划编号',
          width: '260px',
          prop: 'examSerial',
          placeholder: '请输入计划编号',
          isClearable: true,
        },
        {
          type: 'select',
          label: '计划状态',
          prop: 'examStatus',
          width: '260px',
          placeholder: '请选择计划状态',
          options: _EXAM,
          isClearable: true,
        },
      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '计划名称',
          prop: 'examSessionsName',
        },
        {
          label: '考试计划编号',
          prop: 'examSerial',
        },
        {
          label: '考试试卷',
          prop: 'paperTitle',
        },
        {
          label: '考试开始时间',
          prop: 'examStartTime',
        },
        {
          label: '考试结束时间',
          prop: 'examEndTime',
        },
        {
          label: '考核地点',
          prop: 'examSessionAddr',
        },
        // {
        //   label: '考核人数',
        //   prop: 'sureTotal',
        // },
        {
          label: '计划状态',
          prop: 'examStatus',
          formatter: row => Tools.formatDict(examStatus, row.examStatus),
        },
        {
          label: '操作列',
          type: 'button',
          btnList: [
            {
              label: '详情',
              type: 'text',
              handle: (that, row) => that.goDetailsPage(row),
            },
          ],
        },
      ],
      tablePage: {
        pageSize: 10,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },
      tableHandles: [
        {
          label: '新增考试计划',
          type: 'primary',
          handle: () => this.addPlan(),
          show: () => this.userInfo.userType === USER_TYPE.organization.val,
        },
      ],
    }
  },
  created() {
    this.userInfo = getUserInfo()
    this.getTableData()
  },
  methods: {
    ...mapMutations('plan', ['saveExamSessionsId', 'saveExamSessionAddr']),
    // 表格信息
    async getTableData() {
      const _editData = JSON.parse(JSON.stringify(this.searchData))
      const query = {
        pageCondition: this.tablePage,
        bizCondition: _editData,
      }
      this.tableLoading = true
      const { data } = await examList(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 搜索
    searchFormClick() {
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    // 新增计划
    addPlan() {
      this.dialogShow = true
    },
    // 跳转计划详情页
    goDetailsPage(row) {
      this.saveExamSessionsId(row.examSessionsId)
      this.saveExamSessionAddr(row.examSessionAddr)
      // this.$emit('goDetailsPage')
      this.$router.push(`/exam-management/examDetail`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../components/styles/index";
</style>
