import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'
// 培训管理
const trainningPlansRouter = {
  path: '/train',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '培训管理',
    icon: 'train',
    roles: [USER_TYPE.organization.val, USER_TYPE.government.val],
  },
  alwaysShow: true,
  children: [
    {
      path: 'plan',
      component: () => import('@/views/company/trainning-plan'),
      name: 'OrgStudentManagement',
      meta: {
        title: '培训计划',
        icon: null,
        roles: [USER_TYPE.organization.val],
      },
    },
    {
      path: 'planDetails',
      component: () => import('@/views/company/trainning-plan/detail'),
      name: 'planDetails',
      hidden: true,
      meta: {
        title: '培训计划',
        icon: null,
        activeMenu: '/train/plan',
        roles: [USER_TYPE.organization.val, USER_TYPE.government.val],
      },
    },
  ],
}

export default trainningPlansRouter
