import request from '@/utils/request'

// 学员列表
export function getStudentList(data) {
  return request({
    url: '/student/getStudentList',
    method: 'post',
    data,
  })
}

// 验证单个学员
export function checkSingleStudent(data) {
  return request({
    url: '/student/checkSingleStudent',
    method: 'post',
    data,
  })
}

// 新增单个学员
export function singleSaveStudent(data) {
  return request({
    url: '/student/singleSaveStudent',
    method: 'post',
    data,
  })
}

// 编辑单个学员
export function upStudent(data) {
  return request({
    url: '/student/upStudent',
    method: 'post',
    data,
  })
}

// 单个学员详情
export function getStudentByIds(id) {
  return request({
    url: '/student/getStudentByIds?stuId=' + id,
    method: 'get',
  })
}

// 承包商列表
export function contractorList(data) {
  return request({
    url: '/contractor/list',
    method: 'post',
    data,
  })
}

// 承包商新增
export function contractorSave(data) {
  return request({
    url: '/contractor/save',
    method: 'post',
    data,
  })
}

// 承包商删除
export function contractorDelete(data) {
  return request({
    url: '/contractor/delete',
    method: 'post',
    data,
  })
}

// 查询学员证书
export function queryTheCertificateList(stuId) {
  return request({
    url: '/UserCertificate/queryTheCertificateList/' + stuId,
    method: 'get',
  })
}

// 查询学员培训记录
export function getTrainningList(stuId) {
  return request({
    url: '/student/getTrainningList/' + stuId,
    method: 'get',
  })
}

// 添加证书
export function addTheCertificate(data) {
  return request({
    url: '/UserCertificate/addTheCertificate',
    method: 'post',
    data,
  })
}

// 更新证书
export function updateUserCertificate(data) {
  return request({
    url: '/UserCertificate/updateUserCertificate',
    method: 'post',
    data,
  })
}
