<template>
  <div className="app-container">
    <HSearch
      ref="searchForm"
      :search-data="searchData"
      :search-form="searchForm"
      :search-handle="searchHandle"
      :search-rules="searchRules"
      :that="that"
      label-width="80px"
      size="medium"
    />
    <HTable
      :is-handle="true"
      :is-index="true"
      :is-selection="true"
      :loading="tableLoading"
      :table-cols="tableCols"
      :table-data="tableData"
      :table-handles="tableHandles"
      :table-page="tablePage"
      :that="that"
    />
  </div>
</template>

<script>
import HSearch from '@/components/MySearch'
import HTable from '@/components/MyTable'
import Tools from '@/utils/tools'
import moment from '@/utils/moment'
import { examStatus, TESE_SCHEDULE_STATUS } from '@/const/sys'
import { getExamResultList } from '@/api/company/examResult'
import { mapMutations } from 'vuex'

export default {
  name: 'ExamResultList',
  components: {
    HSearch,
    HTable,
  },
  data() {
    const _EXAM = Tools.mapToSelctList(TESE_SCHEDULE_STATUS)
    return {
      that: this,
      tableLoading: false,
      isAuditDialogShow: false, // 是否显示审核弹框
      rowInfo: {},
      categoryObj: {},
      searchData: {
        examSerial: '',
        examStatus: '',
      },
      searchForm: [
        {
          type: 'input',
          label: '计划编号',
          prop: 'examSerial',
          placeholder: '请输入计划编号',
          isClearable: true,
        },
        {
          type: 'select',
          label: '考试状态',
          prop: 'examStatus',
          width: '260px',
          placeholder: '请选择考试状态',
          options: _EXAM,
          isClearable: true,
        },

      ],
      searchRules: {},
      searchHandle: [
        {
          label: '查询',
          itemStyle: 'margin-right:.8rem',
          type: 'primary',
          icon: '',
          handle: that => that.searchFormClick(that.$refs.searchForm),
        },
        {
          label: '重置',
          itemStyle: 'margin-right:.8rem',
          type: '',
          icon: '',
          handle: that => that.reset(that.$refs.searchForm),
        },
      ],
      tableData: [],
      tableCols: [
        {
          label: '考试计划编号',
          prop: 'examSerial',
        },
        {
          label: '考试开始时间',
          prop: 'examStartTime',
        },
        {
          label: '考试结束时间',
          prop: 'examEndTime',
        },
        {
          label: '考试地点',
          prop: 'examSessionAddr',
        },
        {
          label: '考核人数',
          prop: 'total',
        },
        {
          label: '合格人数',
          prop: 'passTotal',
          formatter: row => moment(row.examEndTime) < moment() ? row.passTotal : '0',
        },
        {
          label: '不合格人数',
          prop: 'failTotal',
          formatter: row => moment(row.examEndTime) < moment() ? row.failTotal : '0',
        },
        {
          label: '通过率',
          prop: 'passRate',
          formatter: row => row.passRate != null ? `${row.passRate * 100}%` : '',
        },
        {
          label: '考试状态',
          prop: 'examStatus',
          formatter: row => Tools.formatDict(TESE_SCHEDULE_STATUS, row.examStatus),
        },
        {
          label: '操作列',
          type: 'button',
          btnList: [
            {
              label: '查看结果',
              type: 'text',
              handle: (that, row) => that.checkClick(row),
            },
          ],
        },
      ],
      tablePage: {
        pageSize: 10,
        total: 0,
        pageNum: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.pageNum = val
          this.getTableData()
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.pageSize = val
          this.getTableData()
        },
      },
      tableHandles: [],
      selectSession: {}, // 选择的计划对象
    }
  },
  // computed: {
  //   ...mapState('classify', {
  //     categoryAllMap: state => state.categoryAllMap,
  //     categoryAllArr: state => state.categoryAllArr,
  //   }),
  // },
  created() {
    this.getTableData()
  },
  methods: {
    ...mapMutations('plan', ['saveExamSessionsId', 'saveExamSessionAddr']),
    // fomatCategory(row) {
    //   const _names = Tools.listStrToName(row.categoryChildId, this.categoryAllMap)
    //   return _names.toString()
    // },
    async getTableData() {
      const query = {
        bizCondition: this.searchData,
        pageCondition: this.tablePage,
      }
      this.tableLoading = true
      const { data } = await getExamResultList(query)
      this.tableData = data.data
      this.tablePage.total = data.totalCount
      this.tableLoading = false
    },
    // 搜索
    searchFormClick() {
      this.tablePage.pageNum = 1
      this.getTableData()
    },
    // 重置
    reset() {
      this.searchData = {}
      this.getTableData()
    },
    checkClick(row) {
      this.saveExamSessionsId(row.examSessionsId)
      this.saveExamSessionAddr(row.examSessionAddr)
      this.$router.push(`/exam-management/examResultsDetail`)
    },
  },
}
</script>
