<template>
  <div>
    <div class="multipleImgUpload">
      <div style="display:inline-block;">
        <el-upload
          ref="upload"
          :before-upload="beforeUpload"
          :data="uploadData"
          :on-success="handleSuccess"
          :show-file-list="false"
          action="https://upload-z2.qiniup.com"
          list-type="picture-card"
        >
          <i class="el-icon-plus" />
        </el-upload>
      </div>
      <div v-for="(item,index) in sonFileList" :key="index" class="color-item" @dblclick="dblclick(index)">
        <img :src="item" alt="">
      </div>
    </div>
    <div v-if="customTips" class="tips">{{ customTips }}</div>
    <div v-else class="tips">
      最多可以上传 <span class="color-red">{{ limit }}</span> 张图片，且单张照片不超过{{ imgSize }}M，<span class="color-red">双击</span>可删除
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getUploadToken } from '@/api/qiniu'
import { ImgHost } from '@/const/config'

export default {
  name: 'MyMultipleImgUpload',
  props: {
    // 文件上传类型，如区分不同验收项目
    // fileType: {
    //   type: String,
    //   default: ''
    // },
    // 文件上传的列表
    myFileList: {
      type: Array,
      default() {
        return []
      },
    },
    field: {
      type: String,
      default: '',
    },
    requestFlag: {
      type: Boolean,
      default: true,
    },
    customTips: {
      type: String,
      default: '',
    },
    limit: {
      type: [String, Number],
      default: 5,
    },
    uploadPath: {
      type: String,
      default: '/',
    },
    imgSize: {
      type: String,
      default: '10',
    },
  },
  data() {
    return {
      uploadData: {},
      sonFileList: [], // 文件列表
    }
  },
  computed: {
    ...mapGetters([
      'upload_token',
    ]),
  },
  watch: {
    myFileList: {
      handler: function(newValue, oldValue) {
        this.sonFileList = newValue
      },
      immediate: true,
    },
  },
  beforeMount() {
    this.requestFlag && this.getQiniuToken()
  },
  methods: {
    ...mapMutations('common', ['SET_UPLOAD_TOKEN']),
    getQiniuToken() {
      getUploadToken().then(response => {
        this.SET_UPLOAD_TOKEN(response.data.token)
      })
    },
    beforeUpload(file) {
      if (!this.sonFileList) {
        this.sonFileList = []
      }
      const isJPGorPNG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isPNG = file.type === 'image/png'
      const isLt10M = file.size / 1024 / 1024 < this.imgSize
      const isLimit = this.sonFileList.length < this.limit
      if (!isJPGorPNG && !isPNG) {
        this.$message.error('上传图片需是 JPG或PNG 格式!')
      }
      if (!isLt10M) {
        this.$message.error('上传图片大小不能超过' + this.imgSize + 'MB!')
      }
      if (!isLimit) {
        this.$message.error(`最多上传${this.limit}张`)
      }
      const fileName = file.name
      this.uploadData.token = this.upload_token
      this.uploadData.key = this.uploadPath + new Date().getTime() + '.' + (fileName.split('.')[1])
      this.uploadData.data = file
      return isJPGorPNG && isLt10M && isLimit
    },
    handleSuccess(res, file) {
      const oneFile = ImgHost + res.key
      this.sonFileList.push(oneFile)
      this.$emit('update:value', this.sonFileList)
      this.$emit('validateField', this.field)
    },
    clearFiles() {
      this.$refs.upload.clearFiles()
    },
    dblclick(e) {
      // 双击删除
      this.sonFileList.splice(e, 1)
      this.$emit('update:value', this.sonFileList)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/variables";

.tips {
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 5px 0 0 0;
  color: #666;
  line-height: 20px;
}

.color-red {
  color: $red;
}

.multipleImgUpload {
  display: flex;
  flex-wrap: wrap;
  //align-items: center;
}

/* 多张 */
::v-deep .el-upload--picture-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px !important;
  height: 120px !important;
  //line-height: 100px !important;
  margin-right: 5px;
  background: #fff;
}

.color-item {
  display: inline-block;
  float: left;
  border: 1px dashed #ddd;
  margin: 0 5px 0 0;
  border-radius: 6px;
  overflow: hidden;

  img {
    display: block;
    width: 120px;
    height: 120px;
  }
}
</style>
