<template>
  <el-dialog :visible.sync="dialogVisible" destroy-on-close title="修改密码" width="800px" @close="close" @open="open">
    <div class="bgc-white pl44">
      <el-form
        ref="editForm"
        :model="editForm"
        :rules="editFormRules"
        label-position="left"
      >
        <div class="flex flex-row align-center pt20">
          <div class="w-90">旧密码：</div>
          <el-input
            v-model="editForm.oldPwd"
            placeholder="请输入旧密码"
            style="width: 300px"
            type="password"
          />
        </div>
        <div class="flex flex-row align-center pt20">
          <div class="w-90">新密码：</div>
          <el-input
            v-model="editForm.newPwd"
            placeholder="请输入新密码（长度6-10位，不限字符）"
            style="width: 300px"
            type="password"
          />
        </div>
        <div class="flex flex-row align-center pt20">
          <div class="w-90">确认新密码：</div>
          <el-input
            v-model="editForm.confirmPwd"
            placeholder="请确认新密码（长度6-10位，不限字符）"
            style="width: 300px"
            type="password"
          />
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button :loading="loading" type="primary" @click="subRestClick">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { updatePwd } from '@/api/common'
import { mapState } from 'vuex'

export default {
  name: 'EditPasswordModal',
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.editForm.confirmPwd !== '') {
          this.$refs.editForm.validateField('confirmPwd')
        }
        callback()
      }
    }
    const validateNewPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.editForm.newPwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      dialogVisible: false,
      // 忘记密码表单
      editForm: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
      },
      // 表单验证
      editFormRules: {
        oldPwd: [{ required: true, message: '请输入', trigger: 'blur' }],
        newPwd: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 6, max: 10, message: '请输入6 至 10 位密码', trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' },
        ],
        confirmPwd: [
          { required: true, message: '请输入', trigger: 'blur' },
          { min: 6, max: 10, message: '请输入6 至 10 位密码', trigger: 'blur' },
          { validator: validateNewPassword, trigger: 'blur' },
        ],
      },
      loading: false,
    }
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
  },
  computed: {
    ...mapState('user', {
      userType: state => state.userType,
    }),
  },
  methods: {
    open() {
      // this.$emit('update:flagVisible', true)
    },
    close() {
      this.$refs['editForm'].resetFields()
      this.dialogVisible = false
      this.$emit('closeEditPassword')
    },
    subRestClick() {
      this.$refs.editForm.validate(async valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.editForm))
          this.loading = true
          updatePwd(data).then(res => {
            this.loading = false
            this.$message.success('修改成功')
            this.$emit('closeEditPassword')
            this.$store.dispatch('user/logout')
            setTimeout(() => {
              this.$router.push(`/?redirect=${this.$route.fullPath}`)
            }, 200)
          }).catch(err => {
            this.loading = false
            this.$message.error(err.msg)
          })

          // if (success) {
          //   this.$message.success('修改成功')
          //   await this.$store.dispatch('user/logout')
          //   this.$router.push(`/login`)
          // } else {
          //   this.loading = false
          //   this.$message.error(msg)
          // }
        } else {
          return false
        }
        // this.$emit('handleLogin', true)
      })
    },
  },
}
</script>

<style>
.el-dialog__header {
  padding: 8px;
}

.el-dialog__title {
  font-size: 14px;
  line-height: 100%;
}

.el-dialog__headerbtn {
  position: absolute;
  top: 9px;
  right: 20px;
}
</style>
