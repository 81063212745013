<template>
  <div class="app-container">
    <div slot="header" class="bgc-white ml12 mt12 mb12 mr12">
      <el-button size="mini" type="primary" @click="goBack">返回</el-button>
    </div>
    <div class="header-title" v-html="videoInfo.courseName" />
    <div class="content bgc-white ml12 mt12 mb12 mr12 pl20 pr20 pt20 pb20">
      <div class="video">
        <div v-if="!isShowVideo" class="photo">
          <!--canvas截取流-->
          <canvas ref="canvas" height="260" width="260" />
          <!--图片展示-->
          <video
            ref="video"
            :class="isVideo === true ? 'isVideo' : ''"
            autoplay
            height="260"
            width="260"
          />
          <div class="sub-btn">
            <el-button
              size="mini"
              type="primary"
              @click="photograph"
            >抓拍
            </el-button>
          </div>
        </div>
        <div v-if="isShowVideo" class="study">
          <video-player
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            class="video-player vjs-custom-skin"
            @ended="onPlayerEnded($event)"
            @pause="onPlayerPause($event)"
            @ready="playerReadied"
            @timeupdate="onPlayerTimeupdate($event)"
          />
        </div>
      </div>
      <div class="menu-list pl12 pt12">
        <div class="title">目录</div>
        <div style="font-weight: 600;">公共课</div>
        <div
          v-if="publicList.length === 0"
          style="background-color: #f1f1f1; text-align: center;font-size: 14px; height: 25px;line-height: 25px;margin-top: 10px"
        >暂无公共课
        </div>
        <!--        <ul v-else style="background-color: #f1f1f1 ; padding: 0 10px">-->
        <!--          <li v-for="(item, index) in publicList" :key="index" @click="goVideo(item)">-->
        <!--            <div style="width: 85%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">-->
        <!--              <span style="margin-right: 10px">{{ index + 1 }}</span>-->
        <!--              <span>{{ item.courseWareName }}</span>-->
        <!--            </div>-->
        <!--          </li>-->
        <!--        </ul>-->
        <ul>
          <li v-for="(item, index) in publicList" :key="index" @click="openVideoClick(item)">
            <img
              v-if="item.courseProfileId === courseProfileId"
              :src="domainName+'edu/pc/images/icon/play.png'"
              height="20"
              width="20"
            >
            <span :title="item.courseWareName"><span style="margin-right: 10px">{{
              index + 1
            }}</span>{{ item.courseWareName }}</span>
            <span v-if="item.recordStatus === LEARNING_PROCESS.init.val && item.courseProfileId === courseProfileId"><i
              class="el-icon-s-data"
              style="color:#42ace2;"
            /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.init.val"><i class="el-icon-remove-outline" /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.studying.val"><i
              class="el-icon-s-data"
              style="color:#42ace2;"
            /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.finish.val"><i
              class="el-icon-success"
              style="color:#3fb24f;"
            /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.error.val"><i
              class="el-icon-warning-outline"
            /></span>
          </li>
        </ul>
        <div style="font-weight: 600 ; margin-top: 20px;">专业课</div>
        <div
          v-if="majorList.length === 0"
          style="background-color: #f1f1f1; text-align: center;font-size: 14px; height: 25px;line-height: 25px;margin-top: 10px"
        >暂无专业课
        </div>
        <ul>
          <li v-for="(item, index) in majorList" :key="index" @click="openVideoClick(item)">
            <img
              v-if="item.courseProfileId === courseProfileId"
              :src="domainName+'edu/pc/images/icon/play.png'"
              height="20"
              width="20"
            >

            <span :title="item.courseWareName"> <span style="margin-right: 10px">{{
              index + 1 + publicList.length
            }}</span>{{ item.courseWareName }}</span>
            <span v-if="item.recordStatus === LEARNING_PROCESS.init.val && item.courseProfileId === courseProfileId"><i
              class="el-icon-s-data"
              style="color:#42ace2;"
            /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.init.val"><i class="el-icon-remove-outline" /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.studying.val"><i
              class="el-icon-s-data"
              style="color:#42ace2;"
            /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.finish.val"><i
              class="el-icon-success"
              style="color:#3fb24f;"
            /></span>
            <span v-else-if="item.recordStatus === LEARNING_PROCESS.error.val"><i
              class="el-icon-warning-outline"
            /></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  endSendTime,
  getCourseWareDetail,
  getCourseWareDetails,
  sendPhoto,
  setInit,
  updateRecord,
} from '@/api/student/course'
import { Loading } from 'element-ui'
import { getUploadToken } from '@/api/qiniu'
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import { AUDIT, BIZLINE, Course_type, LEARNING_PROCESS } from '@/const/sys'
import { getUserInfo } from '@/utils/auth'
import CameraHelper from '@/utils/cameraHelper'
import jquery from 'jquery'
import { mapState } from 'vuex'
import 'videojs-contrib-hls'

const Q = require('q')
const { videoImgPath } = require('@/const/config').studentImgPath
// import { pushRecordsYCY } from '@/api/thirdApi'

export default {
  name: 'StuCourseVideo',
  components: {
    videoPlayer,
  },
  props: {
    videoInfo: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      // 是否显示视频
      isShowVideo: true,
      // 降低video层级
      isVideo: false,
      // 视频播放地址
      videoUrl: '123',
      // 当前视频播放时间
      curTime: 0,
      // 视频更新时间
      courseProfileId: null,
      // 当前视屏审核状态
      AuditStatus: '',
      // 选中的课件对象
      checkedCourseWare: null,
      // 目录
      courseWareDetails: [],
      enrollId: 0, // 当前课件的报名号
      intervalTick: 0, // 当前课时定时器
      // 上传数据
      imgData: {
        key: '',
        token: '',
        data: {},
      },
      curPlayObj: '', // 当前视频播放器对象
      playerOptions: {
        // playbackRates: [1.0], //播放速度
        autoplay: true, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          src: '//path/to/video.mp4', // 路径
          type: 'application/x-mpegURL', // 类型
        }],
        // poster: "../../static/images/test.jpg", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 时间分割线
          durationDisplay: true, // 总时间
          remainingTimeDisplay: true, // 剩余播放时间
          progressControl: process.env.NODE_ENV !== 'production', // 进度条
          fullscreenToggle: true, // 全屏按钮
        },
      },
      domainName: process.env.VUE_APP_IMG,
      cameraObj: {}, // 摄像头对象
      snapshotTime: [], // 抓拍时间点
      isSnapshoting: false, // 是否正在进行抓拍
      // 专业课
      majorList: [],
      // 公共课
      publicList: [],
      LEARNING_PROCESS: LEARNING_PROCESS,
      displayNum: 0, // 控制台隐藏次数
    }
  },
  computed: {
    ...mapState('stuCourse', {
      dicKey: state => BIZLINE[state.dicKey].val,
    }),
  },
  created() {
  },
  mounted() {
    // 获取目录
    var that = this
    this.initList().then(
      curCourseware => {
        that.courseProfileId = curCourseware.courseProfileId
        that.AuditStatus = curCourseware.auditStatus
        this.setVideoPlay(that.courseProfileId)
      },
    )

    // 获取七牛云token
    getUploadToken().then(res => {
      // console.log(res)
      if (res.success) {
        that.imgData.token = res.data.token
      }
    })
  },
  beforeDestroy() {
    this.resetCameraAndRefreshRecord()
  },
  methods: {
    // 返回
    goBack() {
      this.$emit('goDetailsPage')
    },
    // 当显示抓拍时，需初始化摄像头抓拍对象
    setCameraObj() {
      const _camera = new CameraHelper(this.$refs['video'], this.$refs['canvas'])
      this.cameraObj = _camera
    },
    // 是否学习完成
    isStudyFinish() {
      if (!this.AuditStatus || this.AuditStatus === AUDIT.init.val || this.AuditStatus === AUDIT.error.val) {
        return false
      } else {
        return true
      }
    },

    initList() {
      const _majorList = []
      const _publicList = []
      const _courseId = this.videoInfo.courseId
      const _enrollId = this.videoInfo.enrollId
      const _courseWareId = this.videoInfo.courseWareId
      this.enrollId = this.videoInfo.enrollId
      return getCourseWareDetails(_enrollId, _courseId).then(res => {
        if (res.success) {
          this.courseWareDetails = res.data
          this.courseWareDetails.forEach(item => {
            if (item.courseAttr === Course_type.major.val || item.courseAttr === Course_type.addMajor.val || item.courseAttr === Course_type.mainMajor.val) {
              _majorList.push(item)
            } else if (item.courseAttr === Course_type.public.val || item.courseAttr === Course_type.addPublic.val || item.courseAttr === Course_type.mainPublic.val) {
              _publicList.push(item)
            }
          })
          this.majorList = _majorList
          this.publicList = _publicList
          var _curCourseWare
          if (this.courseWareDetails.length > 0) {
            _curCourseWare = this.courseWareDetails[0]
          }
          for (var i = 0; i < this.courseWareDetails.length; i++) {
            var item = this.courseWareDetails[i]
            if (item.courseWareId === _courseWareId) {
              _curCourseWare = item
              break
            }
          }
          return _curCourseWare
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              return false
            },
          })
        }
      })
    },
    // 获取视频详情
    /**
     * 点击待播放的视频
     * @param val
     */
    openVideoClick(val) {
      if (val.courseUrl) {
        const that = this
        this.initList({ courseId: that.courseId, enrollId: that.enrollId })
        this.AuditStatus = val.auditStatus
        this.courseProfileId = val.courseProfileId
        this.setVideoPlay(this.courseProfileId).then(rst => {
          if (!rst.success) {
            this.$alert(rst.msg, '提示', {
              confirmButtonText: '确定',
              showClose: false,
              callback: action => {
                return false
              },
            })
          }
        })
      } else {
        const _option = {
          lock: true,
          text: '正在加载',
          spinner: 'el-icon-loading',
          fullscreen: true,
          background: 'rgba(0, 0, 0, 0.7)',
        }

        const _loading = Loading.service(_option)
        setTimeout(() => {
          _loading.close()
        }, 2500)
      }
    },

    // 拍照
    async photograph() {
      // const _headImg = getUserInfo().userInfoVo.studentInfo.headImg
      // if (!_headImg) {
      //   this.$message.error('暂无头像，请点击右上角完善我的信息！')
      //   return
      // }
      // 降低video层级
      this.isVideo = true
      const _imgBase64 = this.cameraObj.photograph()
      const _option = {
        lock: true,
        text: '正在上传，请稍后...',
        spinner: 'el-icon-loading',
        fullscreen: true,
        background: 'rgba(0, 0, 0, 0.7)',
      }
      const loadingInstance = Loading.service(_option)
      let _img = ''
      const rst = await this.uploadImg(_imgBase64)
      if (rst.success) {
        loadingInstance.close()
        _img = rst.data
      } else {
        loadingInstance.close()
        this.$alert('上传失败，请重试', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: action => {
            this.cameraObj.resetCamera()
            return false
          },
        })
      }
      const postData = {
        comparePhoto: _img,
        recordId: this.checkedCourseWare.recordId,
      }
      this.uploadSnapshots(postData)
    },
    uploadSnapshots(data) {
      sendPhoto(data).then(photoRst => {
        if (photoRst.success) {
          this.$message.success('抓拍成功')
          const _snapshotTime = this.snapshotTime
          _snapshotTime.shift()
          this.showVideoPlayer()
          this.cameraObj.closeCamera()
          this.curTime = this.checkedCourseWare.recordTime
        } else {
          this.$alert('上传失败，请重试', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              this.cameraObj.resetCamera()
              return false
            },
          })
        }
      })
    },
    uploadImg(imgBase64) {
      var pic = imgBase64.split(',')[1]
      const { userId } = getUserInfo()
      var imgName = videoImgPath + userId + '/' + new Date().getTime() + '.jpg'
      imgName = btoa(imgName)
      var url = 'https://upload-z2.qiniup.com/putb64/-1/key/' + imgName
      return new Promise(resolve => {
        var xhr = new XMLHttpRequest()
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const _data = {
              success: true,
              data: this.domainName + eval('(' + xhr.responseText + ')').key,
            }
            resolve(_data)
          }
        }
        xhr.open('POST', url, true)
        xhr.setRequestHeader(
          'Content-Type',
          'application/octet-stream',
        )
        xhr.setRequestHeader(
          'Authorization',
          'UpToken' + ' ' + this.imgData.token,
        )
        xhr.send(pic)
      })
    },

    /**
     * 设置视频播放
     * @param courseProfileId 待播放的课件
     */
    setVideoPlay(courseProfileId) {
      return this.getVideoDetail(courseProfileId).then(res => {
        const _data = {
          success: res.success,
          msg: '',
        }
        if (res.success) {
          this.AuditStatus = res.data.auditStatus
          this.curTime = res.data.recordTime
          // 当前播放秒（出现题目用的）
          const videoTime = res.data.recordTime
          this.videoUrl = res.data.courseUrl
          this.playerOptions.sources[0].src = res.data.courseUrl
          const _videoLongTime = res.data.longTime
          if (this.curPlayObj) {
            this.curPlayObj.currentTime(this.curTime)
          }
          this.snapshotTime = this.getSnapshotTime(
            _videoLongTime,
            this.curTime || 0,
          )
          this.showVideoPlayer()
          this.startSaveRecordTime()
          _data.data = res.data
          this.checkedCourseWare = res.data
        } else {
          _data.msg = res.msg
        }
        return _data
      })
    },

    /**
     * 获取抓拍时间点
     * @param longTime 总播放时长
     * @param nowTime 当前播放时长
     */
    getSnapshotTime(longTime, nowTime) {
      // const _times = this.dicKey === BIZLINE.safety_management_continue.val ? 1 : 2 // 抓拍次数，安管继教只抓拍一张
      const _times = 1 // 抓拍次数
      const _longTime = longTime
      // let _halfTime = parseInt((longTime - nowTime) / _times);
      const _snapTime = 5 * 60 // 抓拍时间，单位(秒)
      let _halfTime
      if (longTime - nowTime > _snapTime) {
        _halfTime = parseInt(_snapTime / _times) // 剩余时间内的5分钟抓拍两次
      } else {
        // 剩余时间少于5分钟时，则在剩余时间内抓拍
        _halfTime = parseInt((longTime - nowTime) / _times)
      }
      const _nowTime = nowTime
      if (_longTime - _nowTime <= _times * 10) {
        // 剩余时长分配到每次的抓拍时间不足10秒，则不做抓拍处理
        return []
      }

      const _snapshotTime = []

      let _lastTime = 0 // 上一次计算的随机时间点
      for (var i = 1; i <= _times; i++) {
        let _time = nowTime + Math.floor(Math.random() * _halfTime * i)
        // 上一次的时间点和当前这一次的时间点间隔少于5秒，则将这一次的时间点往后移5秒
        if (_time - _lastTime < 5) {
          _time += 5
        }
        _lastTime = _time
        _snapshotTime.push(_time)
      }
      // console.log(_snapshotTime)
      // return process.env.NODE_ENV === 'production' ? _snapshotTime : []
      console.log(_snapshotTime)
      return _snapshotTime
    },
    /**
     * 获取视频详情
     * @param courseProfileId 待播放的课件
     */
    getVideoDetail(courseProfileId) {
      const _courseProfileId = courseProfileId
      return setInit({
        courseProfileId: _courseProfileId,
        enrollId: this.enrollId,
      })
        .then(d => {
          return getCourseWareDetail(this.enrollId, _courseProfileId)
        })
    },
    /**
     * 播放器暂停
     * @param player
     */
    onPlayerPause(player) {
      const _currentTime = player.currentTime()
      this.updateRecordTime(_currentTime)
    },
    /**
     * 更新当前时间点
     * @param currentTime，待更新的时间点，如果不传，则从当前的播放器对象中获取当前时间
     */
    updateRecordTime(currentTime) {
      if (!this.isSnapshoting) {
        let _currentTime = currentTime
        if (!_currentTime) {
          _currentTime = this.curPlayObj.currentTime()
        }
        return updateRecord({
          courseProfileId: this.courseProfileId,
          enrollId: this.enrollId,
          recordTime: Math.floor(_currentTime),
        })
      } else {
        return new Promise(resolve => {
          resolve(true)
        })
      }
    },
    onPlayerTimeupdate(player) {
      this.checkPlayerVideo(player)
      if (this.snapshotTime.length > 0) {
        const _snapshotTime = this.snapshotTime[0]
        const _currentTime = player.currentTime()
        // 进入抓拍时间点，进行抓拍
        if (!this.isSnapshoting && _currentTime >= _snapshotTime) {
          player.pause()
          this.onPlayerPause(player)// 由于该框架无法调用停止事件，估手动调用下
          this.checkedCourseWare.recordTime = _currentTime
          this.showCamera()
          this.$nextTick(() => {
            this.setCameraObj()
            this.cameraObj.callCamera().then(success => {
              if (success) {
                this.isVideo = true
              } else {
                this.$alert('请使用具备摄像头功能设备进行观看', '提示', {
                  confirmButtonText: '确定',
                  showClose: false,
                  callback: action => {
                    this.goBack()
                  },
                })
              }
            })
          })
        }
      }
    },

    // 检查播放器是否是小窗口模式
    checkPlayerVideo(player) {
      const _num = this.displayNum
      // 3次后还是node属性，则弹出提示
      if (jquery('.vjs-control-bar').css('display') === 'none') {
        if (_num > 3) {
          player.pause()
          this.onPlayerPause(player)// 由于该框架无法调用停止事件，估手动调用下
          // this.$alert('课程学习不支持此功能', '提示', {
          //   confirmButtonText: '确定',
          //   callback: action => {
          //     this.goBack()
          //     return false
          //   },
          // })
          alert('课程学习不支持此模式')
          this.goBack()
        } else {
          this.displayNum++
        }
      } else {
        this.displayNum = 0
      }
    },
    /**
     * 播放结束
     * @param player
     */
    onPlayerEnded(player) {
      // console.log('player ended!', player)
      const _endTime = player.currentTime()
      endSendTime({
        courseProfileId: this.courseProfileId,
        recordTime: _endTime,
        enrollId: this.enrollId,
      })
      this.endSaveRecordTime()
    },
    /**
     * 视频 ready
     * @param player
     */
    playerReadied(player) {
      console.log('play ready')
      if (this.curTime > 0) {
        player.currentTime(this.curTime)
      }
      this.curPlayObj = player
    },
    // 显示视频播放器
    showVideoPlayer() {
      this.isShowVideo = true
      this.isSnapshoting = false
    },
    // 显示相机
    showCamera() {
      this.isShowVideo = false
      this.isSnapshoting = true
    },
    /**
     * 启动定时保存时间点定时器
     */
    startSaveRecordTime() {
      const _time = 60 * 1000// 1分钟保存一次
      this.intervalTick = setInterval(this.updateRecordTime, _time)
    },
    /**
     * 关闭定时保存时间点定时器
     */
    endSaveRecordTime() {
      clearInterval(this.intervalTick)
    },
    // 重置摄像头并更新当前观看记录
    resetCameraAndRefreshRecord() {
      return this.updateRecordTime().finally(() => {
        this.cameraObj.closeCamera()
        this.endSaveRecordTime()
        return false
      })
    },
    // 生成随机数
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    // 题做完
    success() {
      this.$refs.videoPlayer.player.play() // 播放
    },
  },
}
</script>

<style lang="scss" scoped>

.header-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
}

.content {
  //width: 100%;
  height: 700px;
  max-height: 700px;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;

  .video {
    width: 65%;
    //border: 1px solid #ccc;
    position: relative;

    .photo {
      width: 260px;
      height: 260px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      canvas {
        position: absolute;
      }

      video {
        position: absolute;
        object-fit: fill;

        &.isVideo {
          z-index: -1;
        }
      }

      .sub-btn {
        position: absolute;
        top: 270px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .study {
      width: 100%;
      height: 100%;
      //border: 1px solid #ccc;
      //background-color: #000;

      video {
        width: 100%;
        height: 100%;
        margin: auto;
      }
    }
  }

  .menu-list {
    border-radius: 6px;
    background-color: #f5f6f7;
    width: 28%;
    margin-right: 3%;
    max-height: 690px;
    overflow-y: auto;

    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 36px;
      margin-bottom: 20px;
    }

    ul {
      padding-left: 10px;

      li {
        font-size: 16px;
        line-height: 40px;
        width: 100%;
        list-style: none;
        display: flex;
        //justify-content: space-between;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        span {
          width: 18%;

          &:nth-of-type(1) {
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.video {
              color: rgb(29, 139, 233);
            }
          }

          &:nth-of-type(2) {
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            border-radius: 18px;
            color: #000;
            text-align: center;

            &.success {
              background-color: rgb(18, 197, 124);
            }

            &.warning {
              background-color: rgb(241, 179, 22);
            }

            &.error {
              background-color: rgb(242, 20, 20);
            }

            &.finish {
              background-color: rgb(106, 95, 215);
            }
          }
        }
      }
    }
  }
}
</style>
