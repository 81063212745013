var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.powerList.length
      ? _c("div", { staticClass: "login-wrap" }, [
          _c(
            "div",
            {
              staticClass:
                "flex flex-row align-center justify-between select_model",
            },
            _vm._l(_vm.imgList, function (item, index) {
              return _c("img", {
                key: index,
                staticClass: "login_img",
                attrs: {
                  src: require("../../assets/images/" + item.img + ".png"),
                },
                on: {
                  click: function ($event) {
                    return _vm.toNext(item)
                  },
                },
              })
            }),
            0
          ),
        ])
      : _vm._e(),
    !_vm.noPermission
      ? _c(
          "div",
          {
            staticClass:
              "flex flex-row align-center justify-center noData_model",
          },
          [
            _c("img", {
              staticClass: "login_img_noData",
              attrs: {
                src: require("../../assets/images/authLogin_noData.png"),
              },
            }),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }