var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", { staticClass: "f-w700 fs36 header-content" }, [
        _vm._v("化工培训管理系统"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }