<template>
  <el-dialog
    v-loading="isLoading"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :title="info.edit?'编辑证书':'添加证书'"
    :visible.sync="dialogVisible"
    width="800px"
    @close="close"
    @open="open"
  >
    <MyForm
      ref="editForm"
      :edit-cfg="editForm"
      :edit-data="editData"
      :edit-rules="editRules"
      :that="that"
    />
    <div
      v-if="info.edit"
      style="padding-left: 200px;padding-bottom: 20px;color: #F40606 ;cursor: pointer"
      @click="deleteClick"
    >
      删除
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button class="mr10" @click="close">取消</el-button>
      <el-button type="primary" @click="submitBnt">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import MyForm from '@/components/MyForm'
import Tools from '@/utils/tools'
import { CERTIFICATE_TYPE } from '@/const/sys'
import { addTheCertificate, updateUserCertificate } from '@/api/company/students'
// import {addCerticate, updateCerticate} from "@/api/personnelManage";

export default {
  name: 'AddCertificate',
  components: {
    MyForm,
  },
  props: {
    flagVisible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    const _CERTIFICATE_TYPE = Tools.mapToSelctList(CERTIFICATE_TYPE)
    return {
      edit: false,
      dialogVisible: false,
      that: this,
      isLoading: false,
      editForm: [
        {
          type: 'select',
          label: '证书类型',
          prop: 'certificateType',
          placeholder: '请选择',
          options: _CERTIFICATE_TYPE,
          isClearable: true,
        },
        {
          label: '证书编号',
          prop: 'certificateNo',
          type: 'input',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          label: '取证日期',
          prop: 'dateOfIssue',
          type: 'date',
          format: 'yyyy-MM-dd',
          placeholder: '请选择',
          options: [],
          isClearable: true,
        },
        {
          label: '证书有效期结束时间',
          prop: 'certificateEffectiveTime',
          type: 'date',
          format: 'yyyy-MM-dd',
          placeholder: '请选择',
          options: [],
          isClearable: true,
        },
      ],
      editData: {
        certificateType: '',
        certificateNo: '',
        dateOfIssue: '',
        certificateEffectiveTime: '',
      },
      editRules: {
        certificateType: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        certificateNo: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        dateOfIssue: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        certificateEffectiveTime: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
    }
  },
  watch: {
    flagVisible: {
      handler(newValue, oldValue) {
        this.dialogVisible = newValue
      },
    },
  },
  methods: {
    // 打开弹窗时
    async open() {
      if (this.info.edit) {
        // 编辑
        for (const key in this.editData) {
          this.editData[key] = this.info[key]
        }
        this.editData.certificateId = this.info.certificateId
      } else {
        // 新增
      }
    },
    // 关闭弹窗
    close() {
      Tools.resetObject(this.editData)
      this.dialogVisible = false
      this.$emit('update:flagVisible', false)
    },
    deleteClick() {
      this.editData.isDel = 1
      this.submitBnt()
    },
    // 新增、编辑计划提交按钮
    submitBnt() {
      const _editData = JSON.parse(JSON.stringify(this.editData))
      _editData.stuId = this.info.stuId
      this.$refs.editForm.$refs.editForm.validate(async valid => {
        if (valid) {
          let res = ''
          if (this.info.edit) {
            // 编辑
            res = await updateUserCertificate(_editData)
          } else {
            // 新增
            res = await addTheCertificate(_editData)
          }
          this.isLoading = false
          this.$parent.getDetail()
          this.close()
          if (res.data) {
            this.$message.success('操作成功')
          } else {
            this.$message.error('操作失败')
          }
        }
      })
    },
  },
}
</script>
