<template>
  <div class="app-container">
    <div class="wrap">
      <el-button @click="goBack">返回</el-button>
      <div class="course-detail">
        <div class="left-img" />
        <div class="course-info">
          <p style="color:#0799C5">{{ courseName }}</p>
          <p>学习进度：{{ learningProcess }}</p>
        </div>
      </div>
      <div class="pl30 pr30 pt20 pb20">
        <div class="tab-box-hasSwitch">
          <el-tabs v-model="activeName" type="border-card" @tab-click="handleTabsClick">
            <el-tab-pane label="课程列表" name="1" />
            <el-tab-pane label="学习详情" name="2" />
          </el-tabs>
        </div>
        <div v-if="activeName === '1'" style="position:relative;">
          <div class="mt20 mb10 f-bold">公共课</div>
          <div v-if="publicList.length === 0" class="no-curriculum-tips">暂无公共课</div>
          <div v-else class="pl10 pr10 bgc-F1F1F1">
            <div
              v-for="(item, index) in publicList"
              :key="index"
              class="flex flex-row align-center justify-between list-item pointer"
              @click="goVideo(item)"
            >
              <div class="flex align-center flex-row fs14">
                <div class="pr20">
                  {{ index + 1 + publicList.length }}
                </div>
                <div>
                  {{ item.courseWareName }}
                </div>
              </div>
              <div class="flex flex-row align-center">
                <i class="el-icon-time pr6" />
                <span class="pl4 pr6 fs12">{{ $formatTimeDate.getTime(item.longTime) }}</span>
                <i
                  v-if="item.recordStatus === LEARNING_PROCESS.finish.val"
                  class="el-icon-circle-check"
                  style="color:#20cb49;"
                />
                <i v-else class="el-icon-s-data" style="color:#42ace2;" />
              </div>
            </div>
          </div>
          <div class="mt20 mb10 f-bold">专业课</div>
          <div v-if="majorList.length === 0" class="no-curriculum-tips">暂无专业课</div>
          <div v-else class="pl10 pr10 bgc-F1F1F1">
            <div
              v-for="(item, index) in majorList"
              :key="index"
              class="flex flex-row align-center justify-between list-item pointer"
              @click="goVideo(item)"
            >
              <div class="flex align-center flex-row fs14">
                <div class="pr20">
                  {{ index + 1 + publicList.length }}
                </div>
                <div>
                  {{ item.courseWareName }}
                </div>
              </div>
              <div class="flex flex-row align-center">
                <i class="el-icon-time pr6" />
                <span class="pl4 pr6 fs12">{{ $formatTimeDate.getTime(item.longTime) }}</span>
                <i
                  v-if="item.recordStatus === LEARNING_PROCESS.finish.val"
                  class="el-icon-circle-check"
                  style="color:#20cb49;"
                />
                <i v-else class="el-icon-s-data" style="color:#42ace2;" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="activeName === '2'">
          <HTable
            :is-handle="true"
            :is-index="true"
            :loading="tableLoading"
            :table-cols="tableCols"
            :table-data="tableData"
            :table-handles="tableHandles"
            :table-page="tablePage"
            :that="that"
            class="special-style"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HTable from '@/components/MyTable'
import { mapState } from 'vuex'
import { BIZLINE, Course_type, LEARNING_PROCESS } from '@/const/sys'
import Tools from '@/utils/tools'
import { getCourseWareDetails, getOrgRecordDetails } from '@/api/student/course'
import { Loading } from 'element-ui'

export default {
  name: 'StuCourseDetail',
  components: {
    HTable,
  },
  data() {
    return {
      LEARNING_PROCESS: LEARNING_PROCESS,
      // 学员报名信息
      stuInfo: {},
      // 视屏列表
      videoList: [],
      // 专业课
      majorList: [],
      // 公共课
      publicList: [],
      bizLine: '',
      // 标签页
      activeName: '1',
      that: this,
      tableLoading: false,
      // 报名详情弹框显示影藏
      InfoDialogShow: false,
      // 弹框标题
      Title: '',
      tableData: [
        {
          realName: 'zxx',
        },
      ],
      tableCols: [
        {
          label: '课件名称',
          prop: 'courseWareName',
          // width: '250px',
        },
        {
          label: '学习时间',
          prop: 'updateTime',
          width: '160px',
        },
        {
          label: '人脸照片',
          type: 'image',
          prop: 'snapShot1',
        },
        // {
        //   label: '人脸照片2',
        //   type: 'image',
        //   prop: 'snapShot2',
        // },
        {
          label: '学习状态',
          prop: 'recordStatus',
          width: '90px',
          formatter: row => Tools.formatDict(LEARNING_PROCESS, row.recordStatus),
        },
      ],
      tablePage: {
        size: 10,
        total: 0,
        current: 1,
        handlePageNumChange: (that, val) => {
          this.tablePage.current = val
        },
        handlePageSizeChange: (that, val) => {
          this.tablePage.size = val
        },
      },
      tableHandles: [],
    }
  },
  computed: {
    ...mapState('stuCourse', {
      courseName: state => state.courseName,
      expiredTime: state => state.expiredTime,
      learningProcess: state => state.learningProcess,
      courseId: state => state.courseId,
      enrollId: state => state.enrollId,
      dicKey: state => BIZLINE[state.dicKey].txt,
      categoryId: state => state.categoryId,
    }),
  },
  created() {
    this.getVideoList()
  },
  methods: {
    // 点击返回 返回课程列表页
    goBack() {
      this.$emit('goListPage')
    },
    // 标签页切换
    handleTabsClick(tab, event) {
      if (tab.name === '2') {
        // 获取学习详情信息
        this.getStuDetail()
      }
    },
    // 获取视屏列表数据
    async getVideoList() {
      const _majorList = []
      const _publicList = []
      const { data } = await getCourseWareDetails(this.enrollId, this.courseId)
      this.videoList = data
      this.videoList.forEach(item => {
        if (item.courseAttr === Course_type.major.val || item.courseAttr === Course_type.addMajor.val || item.courseAttr === Course_type.mainMajor.val) {
          _majorList.push(item)
        } else if (item.courseAttr === Course_type.public.val || item.courseAttr === Course_type.addPublic.val || item.courseAttr === Course_type.mainPublic.val) {
          _publicList.push(item)
        }
      })
      this.majorList = _majorList
      this.publicList = _publicList
    },
    // 进入视屏播放页
    goVideo(courseInfo) {
      if (courseInfo.courseUrl) {
        courseInfo.enrollId = this.enrollId
        courseInfo.categoryId = this.categoryId
        this.$emit('goVideoPage', courseInfo)
      } else {
        const _option = {
          lock: true,
          text: '正在加载',
          spinner: 'el-icon-loading',
          fullscreen: true,
          background: 'rgba(0, 0, 0, 0.7)',
        }

        const _loading = Loading.service(_option)
        setTimeout(() => {
          _loading.close()
        }, 2500)
      }
    },
    // 获取学习详情数据
    async getStuDetail() {
      this.tableLoading = true
      const { data } = await getOrgRecordDetails(this.courseId, this.enrollId)
      this.tableLoading = false
      const _publicList = [] // 公开课课件
      const _majorList = [] // 专业课课件
      data.res.forEach(item => {
        item.snapShot1 = this.getSnapShotImg(item.snapShotList, 0)
        item.snapShot2 = this.getSnapShotImg(item.snapShotList, 1)
        if (item.courseAttr.indexOf('public') >= 0) {
          _publicList.push(item)
        } else {
          _majorList.push(item)
        }
      })
      this.tableData = _publicList.concat(_majorList)
    },

    // 获取抓拍照片
    getSnapShotImg(snapShotList, index) {
      if (snapShotList && snapShotList[index]) {
        return snapShotList[index]
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>

@import "../../../../components/styles/student";
@import "../../../../components/styles/stu-learning-center";

.special-style {
  margin-left: -30px;
  margin-right: -30px;
}

.list-item {
  height: 36px;
  border-bottom: 1px solid #D9D9D9;
}

.no-curriculum-tips {
  background-color: #f1f1f1;
  text-align: center;
  font-size: 14px;
  height: 25px;
  line-height: 25px;
  margin-top: 10px
}

.tab-box-hasSwitch {
  margin-top: -12px;
}

.app-container {
  background-color: #efefef;

  .course-detail {
    display: flex;
    width: 100%;
    height: 170px;
    background-color: #fff;
    margin-top: 20px;
    padding: 19px 30px;

    .left-img {
      width: 260px;
      height: 133px;
      background: url("~@/assets/images/course_bg.png") 0 0 no-repeat;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .course-info {
      margin-left: 43px;

      p {
        padding: 0;
        margin: 0;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
