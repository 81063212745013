var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      {
        staticClass: "bgc-white ml12 mt12 mb12 mr12",
        attrs: { slot: "header" },
        slot: "header",
      },
      [
        _c(
          "el-button",
          {
            attrs: { size: "mini", type: "primary" },
            on: { click: _vm.goBack },
          },
          [_vm._v("返回")]
        ),
      ],
      1
    ),
    _c("div", {
      staticClass: "header-title",
      domProps: { innerHTML: _vm._s(_vm.videoInfo.courseName) },
    }),
    _c(
      "div",
      {
        staticClass:
          "content bgc-white ml12 mt12 mb12 mr12 pl20 pr20 pt20 pb20",
      },
      [
        _c("div", { staticClass: "video" }, [
          !_vm.isShowVideo
            ? _c("div", { staticClass: "photo" }, [
                _c("canvas", {
                  ref: "canvas",
                  attrs: { height: "260", width: "260" },
                }),
                _c("video", {
                  ref: "video",
                  class: _vm.isVideo === true ? "isVideo" : "",
                  attrs: { autoplay: "", height: "260", width: "260" },
                }),
                _c(
                  "div",
                  { staticClass: "sub-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: { click: _vm.photograph },
                      },
                      [_vm._v("抓拍 ")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isShowVideo
            ? _c(
                "div",
                { staticClass: "study" },
                [
                  _c("video-player", {
                    ref: "videoPlayer",
                    staticClass: "video-player vjs-custom-skin",
                    attrs: { options: _vm.playerOptions, playsinline: true },
                    on: {
                      ended: function ($event) {
                        return _vm.onPlayerEnded($event)
                      },
                      pause: function ($event) {
                        return _vm.onPlayerPause($event)
                      },
                      ready: _vm.playerReadied,
                      timeupdate: function ($event) {
                        return _vm.onPlayerTimeupdate($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _c("div", { staticClass: "menu-list pl12 pt12" }, [
          _c("div", { staticClass: "title" }, [_vm._v("目录")]),
          _c("div", { staticStyle: { "font-weight": "600" } }, [
            _vm._v("公共课"),
          ]),
          _vm.publicList.length === 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "#f1f1f1",
                    "text-align": "center",
                    "font-size": "14px",
                    height: "25px",
                    "line-height": "25px",
                    "margin-top": "10px",
                  },
                },
                [_vm._v("暂无公共课 ")]
              )
            : _vm._e(),
          _c(
            "ul",
            _vm._l(_vm.publicList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.openVideoClick(item)
                    },
                  },
                },
                [
                  item.courseProfileId === _vm.courseProfileId
                    ? _c("img", {
                        attrs: {
                          src: _vm.domainName + "edu/pc/images/icon/play.png",
                          height: "20",
                          width: "20",
                        },
                      })
                    : _vm._e(),
                  _c("span", { attrs: { title: item.courseWareName } }, [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(_vm._s(index + 1)),
                    ]),
                    _vm._v(_vm._s(item.courseWareName)),
                  ]),
                  item.recordStatus === _vm.LEARNING_PROCESS.init.val &&
                  item.courseProfileId === _vm.courseProfileId
                    ? _c("span", [
                        _c("i", {
                          staticClass: "el-icon-s-data",
                          staticStyle: { color: "#42ace2" },
                        }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.init.val
                    ? _c("span", [
                        _c("i", { staticClass: "el-icon-remove-outline" }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.studying.val
                    ? _c("span", [
                        _c("i", {
                          staticClass: "el-icon-s-data",
                          staticStyle: { color: "#42ace2" },
                        }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.finish.val
                    ? _c("span", [
                        _c("i", {
                          staticClass: "el-icon-success",
                          staticStyle: { color: "#3fb24f" },
                        }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.error.val
                    ? _c("span", [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticStyle: { "font-weight": "600", "margin-top": "20px" } },
            [_vm._v("专业课")]
          ),
          _vm.majorList.length === 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    "background-color": "#f1f1f1",
                    "text-align": "center",
                    "font-size": "14px",
                    height: "25px",
                    "line-height": "25px",
                    "margin-top": "10px",
                  },
                },
                [_vm._v("暂无专业课 ")]
              )
            : _vm._e(),
          _c(
            "ul",
            _vm._l(_vm.majorList, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _vm.openVideoClick(item)
                    },
                  },
                },
                [
                  item.courseProfileId === _vm.courseProfileId
                    ? _c("img", {
                        attrs: {
                          src: _vm.domainName + "edu/pc/images/icon/play.png",
                          height: "20",
                          width: "20",
                        },
                      })
                    : _vm._e(),
                  _c("span", { attrs: { title: item.courseWareName } }, [
                    _c("span", { staticStyle: { "margin-right": "10px" } }, [
                      _vm._v(_vm._s(index + 1 + _vm.publicList.length)),
                    ]),
                    _vm._v(_vm._s(item.courseWareName)),
                  ]),
                  item.recordStatus === _vm.LEARNING_PROCESS.init.val &&
                  item.courseProfileId === _vm.courseProfileId
                    ? _c("span", [
                        _c("i", {
                          staticClass: "el-icon-s-data",
                          staticStyle: { color: "#42ace2" },
                        }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.init.val
                    ? _c("span", [
                        _c("i", { staticClass: "el-icon-remove-outline" }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.studying.val
                    ? _c("span", [
                        _c("i", {
                          staticClass: "el-icon-s-data",
                          staticStyle: { color: "#42ace2" },
                        }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.finish.val
                    ? _c("span", [
                        _c("i", {
                          staticClass: "el-icon-success",
                          staticStyle: { color: "#3fb24f" },
                        }),
                      ])
                    : item.recordStatus === _vm.LEARNING_PROCESS.error.val
                    ? _c("span", [
                        _c("i", { staticClass: "el-icon-warning-outline" }),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }