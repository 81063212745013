<!-- 我的详情（卡片式，固定宽度520） -->
<template>
  <div class="mt12 ml12 mr12 bgc-white br2">
    <div class="flex flex-row align-center justify-between pt12 pb12 pl16 pr16 bb-d9">
      <div class="fs20 c-181818">{{ detailTitle }}</div>
      <div v-if="noData" class="flex flex-row align-center fs14">
        <div
          v-for="(item,index) in listHandles"
          v-if="item.isShow === null || item.isShow === undefined ? true : item.isShow(that)"
          :key="index"
          :class="[item.disabled &&item.disabled(that)?'c-7b7b7b':(item.classStyle?item.classStyle:'c-1082E3')]"
          class="pr6 pointer"
          @click="item.disabled &&item.disabled(that)?'':item.handle(that)"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
    <div :style="contentPadding" class="flex flex-wrap  pt16 pb4 pl16 pr16 c-181818 fs14">
      <div
        v-for="(item,index) in listConfig"
        :key="index"
        :style="itemWidth"
        class="flex flex-row pb12"
      >
        <div :class="item.titleClass">{{ item.placeholder }}：</div>
        <!--        单图片展示-->
        <div v-if="item.type==='image'">
          <el-image
            v-if="list[item.prop]"
            :preview-src-list="[list[item.prop]]"
            :src="list[item.prop]"
            class="img148"
            fit="contain"
          />
          <img v-else class="img148" src="~@/assets/images/no-data.png">
        </div>
        <!--        多图片展示-->
        <div v-else-if="item.type==='multipleImage'">
          <div v-if="list[item.prop]&&list[item.prop].length>0">
            <el-image
              v-for="(imgItem,imgIndex) in list[item.prop]"
              :key="imgIndex"
              :preview-src-list="list[item.prop]"
              :src="imgItem"
              style="width: 100px; height: 100px;margin-right: 10px"
            />
          </div>
          <div v-else>
            <img class="img148" src="~@/assets/images/no-data.png">
          </div>
        </div>
        <!--        需要点击下载的链接（文件上传）-->
        <div v-else-if="item.isLink" :class="[list[item.prop]?item.contentClass:'']">
          <div
            v-for="(fileName,fileIndex) in conversion(list[item.prop])"
            :key="fileIndex"
            class="mb10 pointer"
            @click="toLink(list[item.prop][fileIndex].url)"
          >
            {{ fileName || '-' }}
          </div>
        </div>
        <!--        文字展示-->
        <div v-else :class="[list[item.prop]?item.contentClass:'']">
          {{ (item.formatter && item.formatter(list)) || list[item.prop] || '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MyDetail',
  props: {
    that: {
      type: Object,
      default: this,
    },
    // 顶部左边标题配置
    detailTitle: {
      type: String,
      default: '基本信息',
    },
    // key配置
    listConfig: {
      type: Array,
      default: [],
    },
    // 顶部右边按钮配置
    listHandles: {
      type: Array,
      default: () => [],
    },
    // val配置
    list: {
      type: Object,
      default: {},
    },
    // 按钮配置
    formHandler: {
      type: Array,
      default: () => [],
    },
    // 单个item的宽度（样式）
    itemWidth: {
      type: Object,
      default: () => ({ width: '31%', marginRight: '2%' }),
    },
    // 整体居中参数（样式）
    contentPadding: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      srcList: [
        'https://res.cyedtech.cn/hbworkersys/organization/attach/384/1684200768384.png',
        'https://res.cyedtech.cn/hbworkersys/organization/attach/384/1684201223823.jpg',
      ],
      noData: false, // 解决数据与右上角操作按钮不同步的问题
    }
  },
  watch: {
    list: {
      handler: function(newValue, oldValue) {
        if (newValue) {
          if (JSON.stringify(newValue) !== '{}' || newValue !== null || newValue !== undefined) {
            this.$set(this, 'noData', true)
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 文件类型筛选显示内容
    conversion(val) {
      if (val && val.length) {
        return val = val.map(f => {
          return f.name
        })
      } else {
        return val
      }
    },
    // 下载文件
    toLink(url) {
      if (url) {
        window.open(url, '_blank')
      }
    },
  },
}

</script>

