import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'
// 考试管理
const examManagementRouter = {
  path: '/exam-management',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '考试管理',
    icon: 'examination',
    roles: [USER_TYPE.organization.val],
  },
  alwaysShow: true,
  children: [
    {
      path: 'examPlan',
      component: () => import('@/views/company/exam/examPlan'),
      name: 'examPlan',
      meta: {
        title: '线上考试计划',
        icon: null,
      },
    },
    {
      path: 'examDetail',
      component: () => import('@/views/company/exam/examPlan/detail'),
      name: 'examDetail',
      hidden: true,
      meta: {
        title: '考试列表',
        icon: null,
        activeMenu: '/exam-management/examPlan',
      },
    },
    {
      path: 'examResults',
      component: () => import('@/views/company/exam/examResults'),
      name: 'examResults',
      meta: {
        title: '线上考试结果',
        icon: null,
      },
    },
    {
      path: 'examResultsDetail',
      component: () => import('@/views/company/exam/examResults/detail'),
      name: 'examResultsDetail',
      hidden: true,
      meta: {
        title: '考试结果',
        icon: null,
        activeMenu: '/exam-management/examResults',
      },
    },
  ],
}

export default examManagementRouter
