<template>
  <div :class="classObj" class="app-wrapper">
    <div class="newLogo">
      <div class="flex flex-row  align-center justify-between">
        <div style="font-size: 0">
          <img src="../assets/images/top-wz.png">
        </div>
        <div class="pr16 flex flex-row align-center">
          <span class="c-AFC5E3 fs20 pr20">{{ nowDateTime }}</span>
          <img class="pr20 pointer" src="../assets/images/enlarge.png" @click="enlargeClick()">
          <el-dropdown>
            <div class="el-dropdown-link">
              <img class="pointer" src="../assets/images/user.png">
            </div>
            <!--            <el-dropdown-menu slot="dropdown">-->
            <!--              <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>-->
            <!--              <el-dropdown-item icon="el-icon-circle-plus">狮子头</el-dropdown-item>-->
            <!--              <el-dropdown-item icon="el-icon-circle-plus-outline">螺蛳粉</el-dropdown-item>-->
            <!--              <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>-->
            <!--              <el-dropdown-item icon="el-icon-circle-check">蚵仔煎</el-dropdown-item>-->
            <!--            </el-dropdown-menu>-->
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in menusInfo"
                :key="index"
              >
                {{ item.title }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div :class="{hasTagsView:needTagsView}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div>
  </div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
import { getUserInfo } from '@/utils/auth'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,

  },
  mixins: [ResizeMixin],
  data() {
    return {
      nowDateTime: '',
      menusInfo: [],
    }
  },
  created() {
    this.setMenus()
    this.currentTime()
  },
  computed: {
    ...mapState({
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      showSettings: state => state.settings.showSettings,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader,
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile',
      }
    },
  },
  methods: {
    setMenus() {
      if (getUserInfo()) {
        this.menusInfo.push(
          // {
          //   title: '用户名：' + getUserInfo().userName,
          // },
          {
            title: '姓名：' + getUserInfo().realName,
          },
          {
            title: '组织：' + getUserInfo().orgName,
          },
        )
      }
    },
    enlargeClick() {
      document.documentElement.webkitRequestFullScreen()
    },
    currentTime() {
      setInterval(this.getTime, 500)
    },
    getTime() {
      const _this = this
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const hh = new Date().getHours()
      const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      _this.nowDateTime = yy + '-' + mm + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.newLogo {
  height: 80px;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: linear-gradient(#0A1117, #1D3650);
  z-index: 2;
}

.app-wrapper {
  @include clearfix;

  position: relative;
  width: 100%;
  height: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px)
}

.mobile .fixed-header {
  width: 100%;
}
</style>
