// import { _local } from '@/utils/local'
// import { USERINFO } from '@/const/sys'
import { mapGetters, mapState } from 'vuex'
import { getAuthQrcode } from '@/api/admin/student/common'
import { getUserInfo } from '@/utils/auth'

const homePage = {
  gov: '/government/homePage',
  org: '/orgMechanism/homePage',
  // student: '/student/homePage',
  student: '/student/learningCenter/myCourses',
  exam: '/examination/examPlaceManagement/examPlaceList', // examPlaceList
}

export default {
  data() {
    return {
      isEditPassword: false,
      isEditStudentInfo: false,
      isCertificate: false,
      isAuth: false,
      homePage: '',
      authQrcode: null,
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    dropdownMenus() {
      const menusInfo = {
        titleName: '',
        menus: [],
      }
      if (this.userInfo && this.userInfo.govInfo) {
        this.homePage = homePage.gov
        const { govInfo, govInfo: { orgType, orgUserType }} = this.userInfo
        menusInfo.titleName = govInfo.name + ' ' + govInfo.orgUserName || ''
      }
      if (this.userInfo && this.userInfo.orgInfo) {
        // this.homePage = homePage.exam
        this.homePage = homePage.org
        menusInfo.titleName = this.userInfo.orgInfo.name + ' ' + this.userInfo.orgInfo.orgUserName || ''
      }
      if (this.userInfo && this.userInfo.studentInfo) {
        this.homePage = homePage.student
        menusInfo.titleName = this.userInfo.studentInfo.realName || '新用户'
        menusInfo.menus.push(
          {
            title: '我的信息',
            click: this.editStudentInfo,
          },
        )
      }
      if (this.userInfo && this.userInfo.studentInfo) {
        menusInfo.menus.push(
          {
            title: '修改密码',
            click: this.editPassword,
          },
          {
            title: '退出登录',
            click: this.loginOutClick,
          },
        )
      } else {
        menusInfo.menus.push(
          {
            title: '修改密码',
            click: this.editPassword,
          },
          {
            title: '退出登录',
            click: this.loginOutClick,
          },
        )
      }
      return menusInfo
    },
    ...mapState('user', {
      userType: state => state.userType,
    }),
  },
  created() {
    // this.bindDropdownList()
  },
  methods: {
    toBackend() {
      this.$router.push(this.homePage)
    },
    async loginOutClick() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/`)
      // this.$router.push(`/?redirect=${this.$route.fullPath}`)
      // // this.$router.push(`/admins/index`)
    },
    editPassword() {
      this.isEditPassword = true
    },
    editStudentInfo() {
      // this.isEditStudentInfo = true
      this.$router.push(`/student/learningCenter/EditStudentInfoPage`)
    },
    certificate() {
      this.isCertificate = true
    },
    openAuth() {
      const localUserInfo = getUserInfo().studentInfo
      if (localUserInfo.isRealNameAuth) {
        this.$message.success('您已通过实名认证')
        return
      }
      getAuthQrcode(localUserInfo.stuId).then(res => {
        const blob = new Blob([res], {
          type: 'application/png;charset=utf-8',
        })
        this.authQrcode = window.URL.createObjectURL(blob)
        this.isAuth = true
      })
    },
  },
}
