<template>
  <header>
    <div class="f-w700 fs36 header-content">化工培训管理系统</div>

  </header>
</template>

<script>
import dropDownList from '@/mixins/dropDownList'
import { mapGetters } from 'vuex'
import { getToken, getUserInfo } from '@/utils/auth'

export default {
  name: 'Header',
  mixins: [dropDownList],
  data() {
    return {
      url: process.env.VUE_APP_IMG,
      navs: [
        {
          name: '首页',
          key: 'front-home',
        },
        {
          name: '选课中心',
          key: 'course-selection',
        },
        {
          name: '商学院',
          key: 'business-school',
        },
        {
          name: '精品课',
          key: 'excellent-course',
        },
        {
          name: '空中课堂',
          key: 'air-class',
        },
      ],
      keywords: '',
    }
  },
  computed: {
    ...mapGetters([
      'userInfo',
    ]),
    isLogin() {
      return !!(getUserInfo() && getToken())
    },
    usrInfo() {
      return getUserInfo() || {}
    },
    activeItem() {
      return this.$route.name
    },
  },
  methods: {
    logout() {

    },
    handleLink(nav, index) {
      if (nav.key === 'business-school' || nav.key === 'excellent-course' || nav.key === 'air-class') {
        return
      }
      this.$router.push({
        name: nav.key,
      })
    },
    closeEditPassword() {
      this.isEditPassword = false
    },
    closeEditStudentInfo() {
      this.isEditStudentInfo = false
    },
    closeCertificate() {
      this.isCertificate = false
    },
    closeAuth() {
      this.isAuth = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/layout/front/styles/header";
</style>
