<template>
  <div class="app-container">
    <div class="wrap">
      <div class="childTab-box-hasSwitch">
        <el-tabs v-model="childActiveName" @tab-click="handleChildTabsClick">
          <el-tab-pane label="全部" name="1" />
        </el-tabs>
      </div>
      <!--没有课程显示-->
      <div v-if="courseList.length === 0" class="empty-page flex flex-column align-center justify-center">
        <img src="../../../../../assets/images/no-data-2.png">
        <div class="f-bold fs16 pt20">暂无数据</div>
      </div>
      <!--有课程的列表显示-->
      <div v-else class="have-course">
        <div class="course-list">
          <div
            v-for="(item, index) in courseList"
            :key="index"
            class="course-item flex flex-row align-center"
          >
            <div class="img_wrap flex align-center justify-center">
              <img src="../../../../../assets/images/curriculum-icon.png">
            </div>
            <div class="flex flex-column justify-between course-item-right flex-1">
              <div class="course-name fs14">
                <div>{{ item.courseName }}</div>
                <div class="pt6 c-7b7b7b">{{ item.name }}</div>
              </div>
              <div class="text-right fs12 c-1082E3 pointer" @click="goCourseDetail(item)">
                进入课程 >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryStudentCourseDetails } from '@/api/student/course.js'
import { mapMutations } from 'vuex'
import { ACTIVE_STATUS, BIZLINE } from '@/const/sys'

export default {
  name: 'StuCourseList',
  data() {
    return {
      ACTIVE_STATUS,
      BIZLINE: BIZLINE,
      // 标签页
      activeName: '1',
      // 子标签页
      childActiveName: '1',
      // 课程数据
      courseList: [],
    }
  },
  created() {
    this.getCourseData({ bizLine: '', expiredType: '' })
  },
  methods: {
    ...mapMutations('stuCourse', ['saveCourseName', 'saveExpiredTime', 'saveLearningProcess', 'saveCourseId', 'saveEnrollId', 'saveBizLine', 'asveCategoryId', 'saveStuNo']),
    // 获取我的课程信息
    async getCourseData(val) {
      const { data } = await queryStudentCourseDetails(val)
      const _courseList = []
      data.forEach(item => {
        if (BIZLINE[item.dicKey]) {
          item.name = BIZLINE[item.dicKey].txt
          if (item.expiredTime && new Date(item.expiredTime).getTime() < new Date().getTime()) {
            // 有效时间小于当前时间 设置过期属性
            item.localData = 'overdue'
          } else {
            item.localData = 'unOverdue'
          }
          _courseList.push(item)
        }
      })
      this.courseList = _courseList
    },
    // 标签页切换
    handleTabsClick(tab, event) {
      // console.log(tab.name)
    },
    // 子标签页切换
    handleChildTabsClick(tab, event) {
      // console.log(tab.name)
      if (tab.name === '1') {
        this.getCourseData({ bizLine: '', expiredType: '' })
      } else if (tab.name === '2') {
        const obj = { bizLine: '', expiredType: '0' }
        this.getCourseData(obj)
      } else if (tab.name === '3') {
        const obj = { bizLine: '', expiredType: '1' }
        this.getCourseData(obj)
      } else if (tab.name === '4') {
        const obj = { bizLine: '', expiredType: '2' }
        this.getCourseData(obj)
      }
    },
    // 进入课程按钮
    goCourseDetail(item) {
      this.saveCourseName(item.courseName)
      this.saveExpiredTime(item.expiredTime)
      this.saveLearningProcess(item.learningProcess)
      this.saveCourseId(item.courseId)
      this.saveEnrollId(item.enrollId)
      this.saveBizLine(item.dicKey)
      this.asveCategoryId(item.categoryId)
      this.saveStuNo(item.stuNo)
      if (item.activeStatus === ACTIVE_STATUS.init.val) {
        this.$alert('课程未激活！', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: action => {
            return false
          },
        })
      } else {
        if (new Date(item.expiredTime).getTime() < new Date().getTime()) {
          this.$alert('课程已过期！请重新报名。', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              return false
            },
          })
        } else {
          this.$emit('goDetailsPage')
        }
      }
    },
    // 跳转选课中心
    goCourseCenter() {
      this.$router.replace('/front/course-selection')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../components/styles/student";
@import "../../../../components/styles/stu-learning-center";
</style>
