import request from '@/utils/request'

// 修改密码
export function updatePwd(data) {
  return request({
    url: '/user/upPassword',
    method: 'post',
    data,
  })
}
