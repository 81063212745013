<template>
  <div>
    <Header v-if="isHead" />
    <router-view :key="key" />
    <Footer />
  </div>
</template>

<script>
import Header from '@/layout/front/components/Header'
import Footer from '@/layout/front/components/Footer'

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isHead: true,
    }
  },
  computed: {
    key() {
      if (this.$route.path === '/index') {
        this.$set(this, 'isHead', false)
      } else {
        this.$set(this, 'isHead', true)
      }
      return this.$route.path
    },
  },
}
</script>

<style>

</style>
