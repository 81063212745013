var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "wrap" },
      [
        _c("el-button", { on: { click: _vm.goBack } }, [_vm._v("返回")]),
        _c("div", { staticClass: "course-detail" }, [
          _c("div", { staticClass: "left-img" }),
          _c("div", { staticClass: "course-info" }, [
            _c("p", { staticStyle: { color: "#0799C5" } }, [
              _vm._v(_vm._s(_vm.courseName)),
            ]),
            _c("p", [_vm._v("学习进度：" + _vm._s(_vm.learningProcess))]),
          ]),
        ]),
        _c("div", { staticClass: "pl30 pr30 pt20 pb20" }, [
          _c(
            "div",
            { staticClass: "tab-box-hasSwitch" },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "border-card" },
                  on: { "tab-click": _vm.handleTabsClick },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "课程列表", name: "1" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "学习详情", name: "2" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.activeName === "1"
            ? _c("div", { staticStyle: { position: "relative" } }, [
                _c("div", { staticClass: "mt20 mb10 f-bold" }, [
                  _vm._v("公共课"),
                ]),
                _vm.publicList.length === 0
                  ? _c("div", { staticClass: "no-curriculum-tips" }, [
                      _vm._v("暂无公共课"),
                    ])
                  : _c(
                      "div",
                      { staticClass: "pl10 pr10 bgc-F1F1F1" },
                      _vm._l(_vm.publicList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "flex flex-row align-center justify-between list-item pointer",
                            on: {
                              click: function ($event) {
                                return _vm.goVideo(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex align-center flex-row fs14",
                              },
                              [
                                _c("div", { staticClass: "pr20" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        index + 1 + _vm.publicList.length
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(item.courseWareName) + " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-row align-center" },
                              [
                                _c("i", { staticClass: "el-icon-time pr6" }),
                                _c("span", { staticClass: "pl4 pr6 fs12" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatTimeDate.getTime(item.longTime)
                                    )
                                  ),
                                ]),
                                item.recordStatus ===
                                _vm.LEARNING_PROCESS.finish.val
                                  ? _c("i", {
                                      staticClass: "el-icon-circle-check",
                                      staticStyle: { color: "#20cb49" },
                                    })
                                  : _c("i", {
                                      staticClass: "el-icon-s-data",
                                      staticStyle: { color: "#42ace2" },
                                    }),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                _c("div", { staticClass: "mt20 mb10 f-bold" }, [
                  _vm._v("专业课"),
                ]),
                _vm.majorList.length === 0
                  ? _c("div", { staticClass: "no-curriculum-tips" }, [
                      _vm._v("暂无专业课"),
                    ])
                  : _c(
                      "div",
                      { staticClass: "pl10 pr10 bgc-F1F1F1" },
                      _vm._l(_vm.majorList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass:
                              "flex flex-row align-center justify-between list-item pointer",
                            on: {
                              click: function ($event) {
                                return _vm.goVideo(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex align-center flex-row fs14",
                              },
                              [
                                _c("div", { staticClass: "pr20" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        index + 1 + _vm.publicList.length
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    " " + _vm._s(item.courseWareName) + " "
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-row align-center" },
                              [
                                _c("i", { staticClass: "el-icon-time pr6" }),
                                _c("span", { staticClass: "pl4 pr6 fs12" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$formatTimeDate.getTime(item.longTime)
                                    )
                                  ),
                                ]),
                                item.recordStatus ===
                                _vm.LEARNING_PROCESS.finish.val
                                  ? _c("i", {
                                      staticClass: "el-icon-circle-check",
                                      staticStyle: { color: "#20cb49" },
                                    })
                                  : _c("i", {
                                      staticClass: "el-icon-s-data",
                                      staticStyle: { color: "#42ace2" },
                                    }),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
              ])
            : _vm._e(),
          _vm.activeName === "2"
            ? _c(
                "div",
                [
                  _c("HTable", {
                    staticClass: "special-style",
                    attrs: {
                      "is-handle": true,
                      "is-index": true,
                      loading: _vm.tableLoading,
                      "table-cols": _vm.tableCols,
                      "table-data": _vm.tableData,
                      "table-handles": _vm.tableHandles,
                      "table-page": _vm.tablePage,
                      that: _vm.that,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }