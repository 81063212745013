/** 浏览器相机帮助类
 */
class CameraHelper {
  /**
   * 相机帮助类构造函数
   * @param videoObj video对象
   * @param canvasObj 照片流对象，便于照片显示
   */
  constructor(videoObj, canvasObj) {
    this.videoObj = videoObj
    this.canvasObj = canvasObj
  }

  /**
   * 唤醒浏览器摄像头
   * @returns 返回摄像头的promise
   */
  callCamera() {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {}
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {
        var getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia ||
          navigator.oGetUserMedia
        if (!getUserMedia) {
          return Promise.reject(
            new Error('getUserMedia is not implemented in this browser'),
          )
        }
        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject)
        })
      }
    }
    return new Promise((resolve, reject) => {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then(success => {
          // 摄像头开启成功
          this.videoObj.srcObject = success
          // 实时拍照效果
          this.videoObj.play()
          resolve(true)
        })
        .catch(() => {
          resolve(false)
        })
    })
  }

  // 拍照
  photograph() {
    const ctx = this.canvasObj.getContext('2d')
    // 把当前视频帧内容渲染到canvas上
    ctx.drawImage(this.videoObj, 0, 0, 260, 260)
    // 转base64格式、图片格式转换、图片质量压缩
    const imgBase64 = this.canvasObj.toDataURL('image/jpeg', 1)
    return imgBase64
  }

  // 关闭摄像头
  closeCamera() {
    if (this.videoObj) {
      if (!this.videoObj.srcObject) return
      const stream = this.videoObj.srcObject
      const tracks = stream.getTracks()
      tracks.forEach(track => {
        track.stop()
      })
      this.videoObj.srcObject = null
    }
  }

  /**
   * 重置摄像头，清空画布
   */
  resetCamera() {
    const _width = this.canvasObj.width
    const _height = this.canvasObj.height
    const ctx = this.canvasObj.getContext('2d')
    ctx.clearRect(0, 0, _width, _height)
    ctx.beginPath()
    this.videoObj.play()
    // const _width = this.canvasObj.width
    // const _height = this.canvasObj.height
    // this.canvasObj.clearRect(0, 0, _width, _height)
    // this.videoObj.play()
  }
}

export default CameraHelper
