var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        {
          staticClass: "header-box flex flex-row align-center justify-between",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("详情")]),
          _c("div", { staticClass: "mouse-style", on: { click: _vm.goBack } }, [
            _vm._v("返回"),
          ]),
        ]
      ),
      _c("myDetail", {
        attrs: {
          list: _vm.list,
          "list-config": _vm.listConfig,
          "list-handles": _vm.listHandles,
          that: _vm.that,
        },
      }),
      _c(
        "div",
        { staticClass: "mt12 ml12 mr12 bgc-white br2 pt12 pb12 pl16 pr16" },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "培训记录", name: "1" } },
                [
                  _c("HTable", {
                    ref: "multipleTable",
                    staticClass: "el_table",
                    attrs: {
                      "is-handle": false,
                      "is-pagination": _vm.isPagination,
                      loading: _vm.tableLoading,
                      "table-cols": _vm.tableColsTrain,
                      "table-data": _vm.tableDataTrain,
                      that: _vm.that,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "证书记录", name: "2" } },
                [
                  _c("HTable", {
                    ref: "multipleTable",
                    staticClass: "el_table",
                    attrs: {
                      "is-handle": true,
                      "is-pagination": _vm.isPagination,
                      loading: _vm.tableLoading,
                      "table-cols": _vm.tableColsCertificate,
                      "table-data": _vm.tableDataCertificate,
                      that: _vm.that,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("addCertificate", {
        attrs: { "flag-visible": _vm.dialogCertificate, info: _vm.info },
        on: {
          "update:flagVisible": function ($event) {
            _vm.dialogCertificate = $event
          },
          "update:flag-visible": function ($event) {
            _vm.dialogCertificate = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }