<template>
  <footer>
    <!--    <div class="content footer-content">-->
    <!--      <div class="left-part">-->
    <!--        <img class="logo-img" :src="require('@/assets/images/logo.png')" alt="shop logo">-->
    <!--        &lt;!&ndash;        <div>众匠智云</div>&ndash;&gt;-->
    <!--      </div>-->
    <!--      <div class="copyright">-->
    <!--        <p>联系我们 | 关于我们</p>-->
    <!--        <p>-->
    <!--          <span class="tel_icon" />-->
    <!--          <span>400-1616-535</span>-->
    <!--        </p>-->
    <!--        <p>-->
    <!--          <span class="site_icon" />-->
    <!--          <span>湖北省武汉市武昌区丁字桥路文安街中南SOHO城A座24层</span>-->
    <!--        </p>-->
    <!--        <p>-->
    <!--          <span><a href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2020021887号-1</a>　copyright 2020武汉驰研教育科技有限公司</span>-->
    <!--        </p>-->
    <!--      </div>-->
    <!--    </div>-->
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
footer {
  background: #595656;
  color: #fff;

  .footer-content {
    display: flex;
    padding: 20px 100px;
    align-items: center;
  }

  .left-part {
    //justify-content: space-between;
    padding: 0 60px 12px 0;
    margin-left: 100px;
    //border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
    margin-bottom: 10px;

    .logo-img {
      display: block;
      width: 78px;
      margin-top: 65px;
      box-sizing: border-box;
    }

  }

  .copyright {
    padding: 20px 0 20px 52px;

    p {
      display: flex;
      align-items: center;
      font-size: 18px;
    }

    .tel_icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url("~@/assets/images/tel.png") 0 0 no-repeat;
      margin-right: 10px;
    }

    .site_icon {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: url("~@/assets/images/site.png") 0 0 no-repeat;
      margin-right: 10px;
    }
  }
}
</style>
