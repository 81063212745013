<template>
  <div class="content-box">
    <div v-if="textResult.result === EXAM.pass.val" class="pass-title">恭喜，您的练习结果为通过 <i
      class="el-icon-check"
      style="font-size: 44px"
    /></div>
    <div v-if="textResult.result === EXAM.fail.val" class="fail-title">抱歉，您没有通过考试 <i
      class="el-icon-close"
      style="font-size: 44px"
    /></div>
    <div class="score-box">
      <div class="score">
        <div style="margin-bottom: 20px">试卷得分</div>
        <div><span class="score-num">{{ textResult.score }}</span><span>分</span></div>
      </div>
    </div>
    <div class="result">
      <div>
        <span><i class="el-icon-check" style="color:#e61d1d; font-size: 20px; font-weight: 600;" />答对：</span>
        <span>{{ rightList.length }}题</span>
      </div>
      <div style="margin: 0 100px;">
        <span><i class="el-icon-close" style="color:#e61d1d; font-size: 20px; font-weight: 600;" />答错：</span>
        <span>{{ errorList.length }}题</span>
      </div>
      <div>
        <span>未答：</span> <span>{{ noAnswerList.length }}题</span>
      </div>
    </div>
    <div>
      <el-button round style="width: 15%;margin-right: 20px" type="warning" @click="toExit">退出考试</el-button>
      <el-button v-if="paperType===PAPER_TYPE.practice.val" round style="width: 15%;" type="primary" @click="toBack">
        重新考试
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

const { EXAM, PAPER_TYPE } = require('@/const/sys')
export default {
  name: 'NewResultPage',
  data() {
    return {
      PAPER_TYPE,
      EXAM: EXAM,
      // 单选题列表
      singleChoiceList: [],
      // 多选列表
      MultiChoiceList: [],
      // 判断列表
      TrueFalseList: [],
      // 案例题列表
      exampleList: [],
      // 答对的
      rightList: [],
      // 答错的
      errorList: [],
      // 没答案
      noAnswerList: [],
    }
  },
  computed: {
    ...mapState('stuExam', {
      paperNo: state => state.paperNo,
      paperType: state => state.paperType,
      stuNo: state => state.stuNo,
      orgId: state => state.orgId,
      sheetNo: state => state.sheetNo,
      textResult: state => state.textResult,
    }),
  },
  created() {
    this.handleResult()
  },
  methods: {
    ...mapMutations('stuExam', [
      'savePaperInfo',
    ]),
    // 处理答题结果
    handleResult() {
      this.textResult.testInfo.forEach(item => {
        if (item.userAnswer === '') {
          this.noAnswerList.push(item)
        } else if (item.userAnswer === item.expectedAnswer) {
          this.rightList.push(item)
        } else if (item.userAnswer !== item.expectedAnswer) {
          this.errorList.push(item)
        }
      })
    },
    // 重新考试
    toBack() {
      this.$emit('toTest')
    },
    // 返回考试列表
    toExit() {
      this.savePaperInfo(null)
      this.$emit('toList')
    },
  },
}
</script>

<style lang="scss" scoped>
.content-box {
  margin-left: 50%;
  transform: translateX(-50%);
  text-align: center;

  .pass-title {
    margin-top: 143px;
    font-size: 30px;
    color: #0799C5;
    margin-bottom: 100px;

    .el-icon-check {
      display: inline-block;
      vertical-align: -5px;
    }
  }

  .fail-title {
    margin-top: 143px;
    font-size: 30px;
    color: #e61d1d;
    margin-bottom: 100px;

    .el-icon-close {
      display: inline-block;
      vertical-align: -5px;
    }
  }

  .score-box {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 167px;
    height: 167px;
    border: 2px solid #DCDDDD;
    border-radius: 50%;
    margin-bottom: 140px;

    .score {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 82px;
      height: 75px;
      text-align: center;

      .score-num {
        font-size: 30px;
        color: #e61d1d;
      }
    }
  }

  .result {
    display: flex;
    margin-bottom: 112px;
    margin-left: 160px;
  }
}
</style>
