var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container" }, [
    _vm.examSessions
      ? _c("div", { staticClass: "wrap flex align-center justify-center" }, [
          _c("img", {
            attrs: { src: require("../../../assets/images/no-tests.png") },
          }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }