<!--表格组件 -->
<template>
  <section class="ces-table-page">
    <!-- 表格操作按钮 -->
    <section v-if="isHandle" :class="isHandle?'ces-handle':''">
      <template v-for="(item,index) in tableHandles">
        <div v-if="item.type=='title'" :key="index" class="fs14 c-7b7b7b pt16">{{ item.label }}</div>
        <el-button
          v-else-if="(item.show ? item.show(that): true)"
          :key="item.label"
          v-loading.fullscreen.lock="item.isLoading ? item.isLoading(that): false"
          :icon="item.icon"
          :plain="item.plain"
          :size="size || item.size"
          :type="item.type"
          @click="item.handle(that)"
        >{{ item.label }}
        </el-button>
      </template>
    </section>
    <template v-if="tableTitle&&tableTitle.label">
      <div class="fs14 pt16 c-7b7b7b bgc-white ml12 mr12 pl18 ">
        {{ tableTitle.label || '' }}
      </div>
    </template>
    <!-- 数据表格 -->
    <section :class="isPagination?'ces-table':'ces-table1'">
      <el-table
        :id="tableId"
        :ref="refTable"
        v-loading="loading"
        :data="tableData"
        :default-selections="defaultSelections"
        :header-cell-style="{backgroundColor:'#f8f8f9', color:'#181818',fontWeight:'400'}"
        :highlight-current-row="isHighlight"
        :row-style="isRowStyle?rowStyle:rowStyle1"
        :size="size"
        :stripe="isStripe"
        max-height="100%"
        @select="select"
        @select-all="selectAll"
        @current-change="chooseTable"
        @selection-change="selectionChange"
        @row-click="rowClick"
      >
        <el-table-column v-if="isSelection" :selectable="selectable" align="center" type="selection" />
        <el-table-column v-if="isIndex" :label="indexLabel" align="center" type="index" width="50" />
        <!-- 数据栏 -->
        <template v-for="(item,index) in tableCols">
          <el-table-column
            v-if="(item.isShow === undefined || item.isShow === null)? true : (item.isShow&&item.isShow(that))"
            :key="index"
            :align="item.align?'center':'center'"
            :label="item.label"
            :prop="item.prop"
            :render-header="item.require?renderHeader:null"
            :show-overflow-tooltip="item.showOverflowTooltip"
            :width="item.width"
          >
            <template slot-scope="scope">
              <!-- html -->
              <span v-if="item.type==='html'" v-html="item.html(scope.row)" />
              <!-- 按钮 -->
              <span
                v-if="item.type==='textButton'"
                :class="item.itemClass && item.item.itemClass(scope.row)"
                :size="size || btn.size"
                :style="item.itemStyle && item.itemStyle(scope.row)"
                @click="item.handle(that,scope.row)"
              >
                {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] || '-' }}
              </span>
              <span v-if="item.type==='button'">
                <template v-for="(btn) in item.btnList">
                  <el-button

                    v-if="btn.isShow === null || btn.isShow === undefined ? true : btn.isShow(that,scope.row)"
                    :key="btn.label"
                    :disabled="btn.disabled && btn.disabled(that,scope.row)"
                    :icon="btn.icon"
                    :loading="scope.row[`${btn.prop}Loading`]"
                    :size="btn.size || size"
                    :style="(btn.style && btn.style(that,scope.row)) || btn.style"
                    :type="btn.type || BtnType"
                    @click="btn.handle(that,scope.row)"
                  >
                    <!--                        {{ btn.label }}-->
                    {{ (btn.formatter && btn.formatter(scope.row)) || scope.row[btn.prop] || btn.label || '-' }}
                  </el-button>
                  <!--                  <span v-if="index !== item.btnList.length -1" :key="index"> | </span>-->
                </template>
                <!--                   {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] }}-->
              </span>
              <!-- 输入框 -->
              <el-input
                v-if="item.type==='input'"
                v-model="scope.row[item.prop]"
                :disabled="item.isDisabled && item.isDisabled(scope.row)"
                :size="size || btn.size"
                @focus="item.focus && item.focus(scope.row)"
              />
              <!-- 下拉框 -->
              <el-select
                v-if="item.type==='select'"
                v-model="scope.row[item.prop]"
                :disabled="item.isDisabled && item.isDisabled(scope.row)"
                :props="item.props"
                :size="size || btn.size"
                @change="item.change && item.change(that,scope.row)"
              >
                <el-option v-for="op in item.options" :key="op.value" :label="op.label" :value="op.value" />
              </el-select>
              <!-- 单选 -->
              <el-radio-group
                v-if="item.type==='radio'"
                v-model="scope.row[item.prop]"
                :disabled="item.isDisabled && item.isDisabled(scope.row)"
                :size="size || btn.size"
                @change="item.change && item.change(that,scope.row)"
              >
                <el-radio v-for="ra in item.radios" :key="ra.value" :label="ra.value">{{ ra.label }}</el-radio>
              </el-radio-group>
              <!-- 复选框 -->
              <el-checkbox-group
                v-if="item.type==='checkbox'"
                v-model="scope.row[item.prop]"
                :disabled="item.isDisabled && item.isDisabled(scope.row)"
                :size="size || btn.size"
                @change="item.change && item.change(that,scope.row)"
              >
                <el-checkbox v-for="ra in item.checkboxs" :key="ra.value" :label="ra.value">{{ ra.label }}</el-checkbox>
              </el-checkbox-group>
              <!-- 评价 -->
              <el-rate
                v-if="item.type==='rate'"
                v-model="scope.row[item.prop]"
                :disabled="item.isDisabled && item.isDisabled(scope.row)"
                :size="size || btn.size"
                @change="item.change && item.change(scope.row)"
              />
              <!-- 开关 -->
              <el-switch
                v-if="item.type==='switch'"
                v-model="scope.row[item.prop]"
                :active-value="item.values&&item.values[0]"
                :disabled="item.isDisabled && item.isDisabled(that, scope.row)"
                :inactive-value="item.values&&item.values[1]"
                :size="size || btn.size"
                @change="item.change && item.change(that, scope.row)"
              />
              <!-- 图像 -->
              <img
                v-if="item.type==='image'&&scope.row[item.prop]"
                :src="scope.row[item.prop]"
                height="50"
                width="50"
                @click="item.handle && item.handle(scope.row)"
              >
              <!-- 滑块 -->
              <el-slider
                v-if="item.type==='slider'"
                v-model="scope.row[item.prop]"
                :disabled="item.isDisabled && item.isDisabled(scope.row)"
                :size="size || btn.size"
                @change="item.change && item.change(scope.row)"
              />
              <el-popover v-if="item.suspension==='suspension'" placement="top" trigger="hover">
                <div :class="item.suspensionClass" style="text-align: center"> {{ scope.row[item.prop] }}</div>
                <div slot="reference" class="name-wrapper pointer">
                  <span
                    v-if="!item.type&&scope.row[item.prop]"
                    :class="item.itemClass && item.item.itemClass(scope.row)"
                    :size="size || btn.size"
                    :style="item.itemStyle && item.itemStyle(scope.row)"
                  >
                    {{
                      item.suspensionName || (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] || '-'
                    }}
                  </span>
                </div>
              </el-popover>
              <!-- 默认 -->
              <div v-else>
                <span
                  v-if="!item.type"
                  :class="item.itemClass && item.item.itemClass(scope.row)"
                  :size="size || btn.size"
                  :style="item.itemStyle && item.itemStyle(scope.row)"
                >
                  {{ (item.formatter && item.formatter(scope.row)) || scope.row[item.prop] || '-' }}
                </span>
              </div>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </section>
    <!-- 分页 -->
    <section v-if="isPagination && tablePage.total !== 0" class="ces-pagination">
      <el-pagination
        :current-page.sync="tablePage.pageNum"
        :page-size="tablePage.pageSize"
        :page-sizes="[10,30,50,100]"
        :popper-class="customPaperStyle ? 'select' : ''"
        :total="tablePage.total"
        background
        class="pagination-style"
        layout="total,prev,pager,next,sizes,jumper"
        @current-change="tablePage.handlePageNumChange(that,arguments[0])"
        @size-change="tablePage.handlePageSizeChange(that,arguments[0])"
      />
    </section>
  </section>
</template>

<script>

export default {
  name: 'HTable',
  props: {
    that: {
      type: Object,
      default: this,
    },
    height: {
      type: Number,
      default: 680,
    },
    // 表格型号：mini,medium,small
    size: {
      type: String,
      default: 'medium',
    },
    refTable: {
      type: String,
      default: 'cesTable',
    },
    isBorder: {
      type: Boolean,
      default: true,
    },
    isStripe: {
      type: Boolean,
      default: false,
    },
    isRowStyle: {
      type: Boolean,
      default: false,
    },
    isHighlight: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // 表格操作
    isHandle: {
      type: Boolean,
      default: false,
    },
    tableHandles: {
      type: Array,
      default: () => [],
    },
    tableTitle: {
      type: Object,
      default: () => {
      },
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    tableId: {
      type: String,
      default: '',
    },
    // 表格列配置
    tableCols: {
      type: Array,
      default: () => [],
    },
    // 是否显示表格复选框
    isSelection: {
      type: Boolean,
      default: false,
    },
    defaultSelections: {
      type: [Array, Object],
      default: () => null,
    },
    // 是否显示表格索引
    isIndex: {
      type: Boolean,
      default: false,
    },
    indexLabel: {
      type: String,
      default: '序号',
    },
    // 是否显示分页
    isPagination: {
      type: Boolean,
      default: true,
    },
    // 分页数据
    tablePage: {
      type: Object,
      default: () => ({
        size: 10,
        total: 3,
        current: 1,
      }),
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    customPaperStyle: {
      type: Boolean,
      default: false,
    },
    BtnType: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      isShow: true,
    }
  },
  watch: {
    'defaultSelections'(val) {
      this.$nextTick(function() {
        if (Array.isArray(val)) {
          val.forEach(row => {
            this.$refs.cesTable.toggleRowSelection(row, true)
          })
        } else {
          this.$refs.cesTable.toggleRowSelection(val)
        }
      })
    },
    // tableData: {
    //   handler (newValue, oldValue) {
    //     if (this.isHighlight) {
    //       if (newValue.length > 0) {
    //         this.$refs.refTable.setCurrentRow(this.tableData[0])
    //       }
    //     }
    //   },
    //   deep: true
    // }
  },
  mounted() {

    // if (this.tableData.length) {
    //   this.$refs.refTable.setCurrentRow(this.tableData[0])
    // }
  },
  methods: {
    // 表格选中
    chooseTable(row) {
      this.$emit('selectRow', row)
    },
    // 表格勾选
    select(rows, row) {
      this.$emit('select', rows, row)
    },
    // 全选
    selectAll(rows) {
      this.$emit('select', rows)
    },
    selectionChange(selection) {
      this.$emit('selectionChange', selection)
    },
    rowClick(row) {
      this.$emit('rowClick', row)
    },
    // 颜色样式
    rowStyle({
      row,
      rowIndex,
    }) {
      // 修改样式（我这里根据不同的状态显示不同的背景色）
      if (row.status) {
        return 'background:' + (row.status === 'CRETE' ? 'rgba(241, 37, 37, 0.1);' : (row.status === 'LOADING' ? 'rgba(229, 232, 20, 0.1)' : (row.status === 'COMPLETE' ? 'rgb(192, 196, 204, 0.1)' : '')))
      } else if (row.actionResult) {
        return 'background:' + (row.actionResult === 'OFFLINE' ? 'rgba(241, 37, 37, 0.1);' : (row.actionResult === 'ONLINE' ? 'rgba(33, 232, 20, 0.1)' : ''))
      }
    },
    rowStyle1() {
    },
    //
    // handleCurrentChange (val) {
    //   this.tablePage.current = val
    //   this.$emit('refresh')
    // },
    // handleSizeChange (val) {
    //   this.tablePage.current = 1
    //   this.tablePage.size = val
    //   this.$emit('refresh')
    // },

    // tableRowClassName({rowIndex}) {
    //     if (rowIndex % 2 === 0) {
    //         return "stripe-row";
    //     }
    //     return "";
    // }
    renderHeader(h, obj) {
      return h('span', { class: 'ces-table-require' }, obj.column.label)
    },
  },
}
</script>
<style lang="scss">
@import "../../styles/variables";

.ces-table-require::before {
  content: '*';
  color: red;
}

.pagination-style {
  .el-input--medium .el-input__inner, .el-input--mini .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
}

.ces-table {
  /*min-height: calc(150px - 50px);*/
  /*max-height: calc(100% - 50px);*/
  flex: 1;
}

.ces-table1 {
  /*min-height: 100px;*/
  /*max-height: 100%;*/
  flex: 1;
}

.newComponents .el-table--scrollable-y .el-table__body-wrapper {
  /* max-height: 100% !important; */
  overflow: auto;
}

.select {
  .el-select-dropdown__item.selected {
    color: $red;
  }
}

.el-input--medium .el-input__inner, .el-input--mini .el-input__inner {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}
</style>
