var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", {
        staticClass: "breadcrumb-container",
        attrs: { id: "breadcrumb-container" },
      }),
      _c("edit-password-modal", {
        attrs: { "flag-visible": _vm.isEditPassword },
        on: { closeEditPassword: _vm.closeEditPassword },
      }),
      _c("edit-student-info", {
        attrs: { "flag-visible": _vm.isEditStudentInfo },
        on: { closeEditStudentInfo: _vm.closeEditStudentInfo },
      }),
      _c("auth-modal", {
        attrs: { "auth-qrcode": _vm.authQrcode, "flag-visible": _vm.isAuth },
        on: { closeAuth: _vm.closeAuth },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }