<template>
  <div class="app-container">
    <div class="header-box flex flex-row align-center justify-between">
      <div class="title">{{ edit ? '编辑' : '添加员工' }}</div>
      <div class="mouse-style" @click="goBack">返回</div>
    </div>
    <MyForm
      ref="editForm"
      :double-row-style="true"
      :edit-cfg="editForm"
      :edit-data="editData"
      :edit-rules="editRules"
      :that="that"
    />
    <div class="buttons">
      <div class="buttons-right">
        <div class="buttons-right-center">
          <el-button class="mr10" type="primary" @click="formValidate">保存</el-button>
          <el-button @click="goBack">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyForm from '@/components/MyForm'
import Tools from '@/utils/tools'
import { DEP, EDUCATION, GENDER, JOB_POSITION } from '@/const/sys'
import { studentImgPath } from '@/const/config'
import { checkSingleStudent, getStudentByIds, singleSaveStudent, upStudent } from '@/api/company/students'
import { getUserInfo } from '@/utils/auth'

export default {
  name: 'StudentsAdd',
  components: {
    MyForm,
  },
  data() {
    const _education = Tools.mapToSelctList(EDUCATION)
    const _gender = Tools.mapToSelctList(GENDER)
    const _JOB_POSITION = Tools.mapToSelctList(JOB_POSITION)
    const _DEP = Tools.mapToSelctList(DEP)
    return {
      that: this,
      edit: false,
      isShowRepeater: false, // 是否显示踏步对数
      editForm: [
        {
          type: 'input',
          label: '姓名',
          prop: 'realName',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          type: 'input',
          label: '身份证号',
          prop: 'idCard',
          placeholder: '请输入',
          isClearable: true,
          maxLength: 18,
        },
        {
          type: 'select',
          label: '性别',
          prop: 'gender',
          placeholder: '请选择',
          options: _gender,
          isClearable: true,
        },
        {
          type: 'input',
          label: '手机号',
          prop: 'phone',
          placeholder: '请输入',
          isClearable: true,
          maxLength: 11,
          disabled: () => this.edit,
        },
        {
          type: 'select',
          label: '工作岗位',
          prop: 'job',
          placeholder: '请选择',
          options: _JOB_POSITION,
          isClearable: true,
        },
        {
          type: 'select',
          label: '学历',
          prop: 'education',
          placeholder: '请选择',
          options: _education,
          isClearable: true,
        },
        {
          type: 'input',
          label: '所学专业',
          prop: 'speciality',
          placeholder: '请输入',
          isClearable: true,
        },
        {
          type: 'select',
          label: '所属部门',
          prop: 'department',
          placeholder: '请选择',
          options: _DEP,
          isClearable: true,
        },
        {
          type: 'multipleImgUpload',
          label: '证件照',
          prop: 'headImg',
          requestFlag: true,
          isPriview: true,
          limit: 1,
          imgSize: '2',
          uploadPath: studentImgPath.headImgPath,
        },
        {
          type: 'multipleImgUpload',
          label: '身份证国徽面',
          prop: 'frontOfIdCard',
          requestFlag: false,
          isPriview: true,
          limit: 1,
          imgSize: '2',
          uploadPath: studentImgPath.headImgPath,
        },
        {
          type: 'multipleImgUpload',
          label: '身份证人像面',
          prop: 'obverseOfIdCard',
          requestFlag: false,
          isPriview: true,
          limit: 1,
          imgSize: '2',
          uploadPath: studentImgPath.headImgPath,
        },
      ],
      editData: {
        realName: '',
        phone: '',
        idCard: '',
        gender: '',
        job: '',
        education: '',
        speciality: '',
        department: '',
        headImg: [],
        frontOfIdCard: [],
        obverseOfIdCard: [],
      },
      editRules: {
        realName: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        idCard: [{ required: true, message: '请输入', trigger: 'blur' }],
        gender: [{ required: true, message: '请选择', trigger: 'change' }],
        job: [{ required: true, message: '请输入', trigger: 'blur' }],
        education: [{ required: true, message: '请选择', trigger: 'blur' }],
        speciality: [{ required: true, message: '请选择', trigger: 'change' }],
        department: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
    }
  },
  created() {
    // 编辑信息数据回显

    if (this.$route.query.stuId) {
      this.edit = true
      this.getInfo()
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async getInfo() {
      const res = await getStudentByIds(this.$route.query.stuId)
      for (const key in res.data) {
        if (res.data[key]) {
          if (key === 'headImg' || key === 'frontOfIdCard' || key === 'obverseOfIdCard') {
            this.editData[key] = [res.data[key]]
          } else {
            this.editData[key] = res.data[key]
            this.editData[key] = res.data[key]
            this.editData[key] = res.data[key]
          }
        }
      }
    },

    formValidate() {
      const _editData = JSON.parse(JSON.stringify(this.editData))
      _editData.headImg = _editData.headImg.join()
      _editData.frontOfIdCard = _editData.frontOfIdCard.join()
      _editData.obverseOfIdCard = _editData.obverseOfIdCard.join()
      _editData.organizationId = getUserInfo().orgInfo.orgId
      _editData.organization = getUserInfo().orgInfo.name
      this.$refs.editForm.$refs.editForm.validate(async valid => {
        if (valid) {
          if (this.edit) {
            const data = await upStudent(_editData)
          } else {
            const res = await checkSingleStudent(_editData)
            if (!res.data) {
              const data = await singleSaveStudent(_editData)
            } else {
              this.$message.error(res.data)
              return
            }
          }
          this.$refs['editForm'].$refs.editForm.resetFields()
          this.$message.success('操作成功')
          this.$router.go(-1)
        } else {
          this.$message.warning('请完善信息')
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
