<template>
  <div class="login-wrap">
    <div class="position-style">
      <div class="flex flex-column title align-center">
        <img class="w-100 text-center" src="../../assets/images/logo-2.png">
        <span>欢迎登录化工培训管理系统</span>
      </div>
      <div class="login bgc-white pt20 pl26 pr26 pb40">
        <el-form
          ref="loginForm"
          :model="loginForm"
          :rules="loginRules"
          autocomplete="on"
          class="login-form"
          label-position="left"
        >
          <div class="form-content">
            <el-tabs v-model="activeName" :stretch="true" @tab-click="handleTabsClick">
              <el-tab-pane :name="LOGIN_TYPE.ORGANIZATION.val" label="企业端" />
              <el-tab-pane :name="LOGIN_TYPE.STUDENT.val" label="员工端" />
            </el-tabs>
            <el-form-item class="input-bg" prop="username">
              <div class="svg-container">
                <svg-icon class-name="icon-style" icon-class="user" />
              </div>
              <el-input
                ref="username"
                v-model="loginForm.username"
                autocomplete="on"
                name="username"
                placeholder="用户名"
                tabindex="1"
                type="text"
              />
            </el-form-item>
            <el-tooltip v-model="capsTooltip" content="Caps lock is On" manual placement="right">
              <el-form-item class="input-bg" prop="password">
                <div class="svg-container">
                  <svg-icon class-name="icon-style" icon-class="password" />
                </div>
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model="loginForm.password"
                  :type="passwordType"
                  autocomplete="on"
                  name="password"
                  placeholder="密码"
                  tabindex="2"
                  @blur="capsTooltip = false"
                  @keyup.native="checkCapslock"
                  @keyup.enter.native="handleLogin"
                />
                <span class="show-pwd" @click="showPwd">
                  <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" class-name="icon-style" />
                </span>
              </el-form-item>
            </el-tooltip>
            <SliderCheck :error-fun="handleErrorFun" :success-fun="handleSuccessFun" />
            <!--              <div class="flex flex-row justify-end pt10">-->
            <!--                <el-button class="c-black" type="text" @click="openRestPassword">忘记密码</el-button>-->
            <!--                <div v-if="activeName === 'student'||activeName === 'exam'" class="rest text-center">-->
            <!--                  <span class="line">|</span>-->
            <!--                  <el-button class="c-black" type="text" @click="openRest">个人注册</el-button>-->
            <!--                </div>-->
            <!--                <el-button class="c-black" type="text" @click="openRest">个人注册</el-button>-->
            <!--              </div>-->
            <el-button
              :loading="loading"
              style="width:100%;background-color: #0486FF;margin-top: 25px"
              type="primary"
              @click.native.prevent="handleLogin"
            >登录
            </el-button>
            <!--            <el-button type="primary" @click="gogogo">临时切换登录(方便调试)</el-button>-->
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import SliderCheck from '@/components/SliderCheck'
import { removeCustomStorage } from '@/utils/auth'
import { LOGIN_TYPE, USER_TYPE } from '@/const/sys'

export default {
  name: 'Home',
  components: {
    SliderCheck,
  },
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请输入密码'))
      } else {
        callback()
      }
    }
    return {
      passwordType: 'password',
      capsTooltip: false,
      loginForm: {
        username: '',
        password: '',
      },
      loginRules: {
        username: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', validator: validatePassword }],
      },
      activeName: LOGIN_TYPE.ORGANIZATION.val,
      LOGIN_TYPE,
      loading: false,
      isRest: false, // 忘记密码弹框
      isSignUp: false, // 注册弹框
      loginStatus: false,
    }
  },
  methods: {
    gogogo() {
      this.$router.push({ path: '/admin/index' })
    },
    handleTabsClick(tab, event) {
      this.activeName = tab.name
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    handleLogin() {
      removeCustomStorage()
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          if (this.loginStatus) {
            this.loading = true
            this.$store.dispatch('user/login', { ...this.loginForm, ...{ userType: this.activeName }})
              .then(() => {
                // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
                // this.$router.push({ path: '/admin/home' })
                if (this.activeName === USER_TYPE.organization.val) {
                  this.$router.replace('/train/plan')
                } else {
                  this.$router.replace('/student/learningCenter/myCourses')
                }
                this.loading = false
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            this.$message.warning('请拖动滑块完成验证')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 滑块验证成功回调
    handleSuccessFun() {
      this.loginStatus = true
    },
    // 滑块验证失败回调
    handleErrorFun() {
    },
    // 个人注册
    openRest() {
      this.$message.info('此功能正在开发中。。。')
      return
      this.isSignUp = true
    },
    // 忘记密码
    openRestPassword() {
      this.isRest = true
    },
  },
}
</script>

<style lang="scss" scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
}

.icon-style {
  color: #7b7b7b;
  //padding-left: 10px;
}

.svg-container {
  display: inline-block;
  padding-left: 20px;
}

.input-bg {
  background-color: #F5F6F8;
  margin-bottom: 30px;
}

.login .el-tabs__item {
  padding: 0 !important;
}

.login .el-tabs__item.is-active {
  color: #0486FF !important;
}

.login .el-tabs__active-bar {
  background-color: #0486FF !important;
}

.login .el-input--medium {
  width: 240px;
  text-align: right;

  /deep/ .el-input__inner {
    font-size: 14px !important;
    border: none !important;
    background-color: transparent !important;
  }
}

.login-wrap {
  height: 100vh;
  background: url("~@/assets/images/home-bg-2.jpg");
  background-size: 100% 100%;

  .title {
    margin-bottom: 70px;

    img {
      width: 100px;
    }

    span {
      color: white;
      font-size: 42px;
      letter-spacing: 2px;
      font-weight: 500;
      margin-top: 20px;
    }
  }
}

.login {
  width: 360px;
  height: 340px;
  border-radius: 10px;
  padding: 20px 30px;
}

.position-style {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -290px;
  margin-top: -350px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-center {
  width: 1125px;
  margin: 0 auto;
}

.vjs-big-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%) !important;
}
</style>

