<template>
  <div class="app-container">
    <div class="wrap">
      <!--没有课程显示-->
      <div v-if="examList.length === 0" class="empty-page  flex flex-column align-center justify-center">
        <img src="../../../../assets/images/no-data-2.png">
        <div class="f-bold fs16 pt20">暂无数据</div>
      </div>
      <!--有课程的列表显示-->
      <div v-else class="have-course">
        <div class="course-list">
          <div v-for="(item, index) in examList" :key="index" class="course-item flex flex-row align-center">

            <div class="img_wrap flex align-center justify-center">
              <img src="../../../../assets/images/practice-icon.png">
            </div>
            <div class="flex flex-column justify-between course-item-right flex-1">
              <div class="course-name fs14">
                <div>{{ item.courseName }}</div>
                <div class="pt6 c-7b7b7b">{{ item.name }}</div>
              </div>
              <div class="text-right fs12 c-1082E3 pointer" @click="goExam(item)">
                进入练习 >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPaperInfoListByBizLine } from '@/api/student/exam.js'
import { mapMutations } from 'vuex'
import { BIZLINE, PAPER_TYPE } from '@/const/sys'
import Tools from '@/utils/tools'

export default {
  name: 'PracticeList',
  data() {
    return {
      // 标签页
      activeName: '1',
      // 考试信息
      examList: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapMutations('stuExam', [
      'savePaperNo', 'savePaperType', 'saveStuNo', 'saveOrgId', 'saveSheetNo', 'saveDicKey',
    ]),
    async getList() {
      const { data } = await getPaperInfoListByBizLine({
        paperType: PAPER_TYPE.practice.val,
      })
      data.forEach(item => {
        item.dicKeyStr = Tools.formatDict(BIZLINE, item.dicKey)
        if (item.expiredTime && new Date(item.expiredTime).getTime() < new Date().getTime()) {
          // 有效时间小于当前时间 设置过期属性
          item.localData = 'overdue'
        } else {
          item.localData = 'unOverdue'
        }
      })
      this.examList = data
    },
    // 进入练习
    goExam(item) {
      if (new Date(item.expiredTime).getTime() < new Date().getTime()) {
        this.$alert('课程已过期！请重新报名。', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: action => {
            return false
          },
        })
        return
      }
      this.savePaperNo(item.paperNo)
      this.savePaperType(item.paperType)
      this.saveStuNo(item.stuNo)
      this.saveOrgId(item.orgId)
      this.saveSheetNo(item.sheetNo)
      this.saveDicKey(item.dicKey)
      if (item.paperType === PAPER_TYPE.practice.val) {
        this.$emit('toTest')
      } else if (item.paperType === PAPER_TYPE.mock.val) {
        if (item.flag) {
          this.$emit('toDetail')
        } else {
          this.$alert('请学习完再进行测试', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            callback: action => {
              return false
            },
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../components/styles/student";
@import "../../../components/styles/stu-learning-center";
</style>
