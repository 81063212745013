<template>
  <div class="ces-search">
    <el-form
      ref="searchForm"
      :model="searchData"
      :rules="searchRules"
      class="searchForm newBorderTop"
      inline
    >
      <el-form-item
        v-for="item in searchForm"
        v-show="item.isShow === null || item.isShow === undefined ? true : item.isShow(that,searchForm[item.prop])"
        :key="item.prop"
        :label="item.label?item.label:''"
        :label-width="item.labelWidth"
        :prop="item.prop"
      >
        <!-- 输入框 -->
        <el-input
          v-if="item.type==='input'"
          v-model="searchData[item.prop]"
          :disabled="item.disable && item.disable(searchData[item.prop])"
          :placeholder="item.placeholder"
          :size="size || item.size"
          :style="{width:item.width}"
          clearable
          @change="item.change && item.change(that,searchData[item.prop])"
          @input.native="item.inputChange&&item.inputChange(that,searchData[item.prop])"
        />
        <!-- 级联选择 -->
        <el-cascader
          v-if="item.type==='cascader'"
          ref="mycascader"
          v-model="searchData[item.prop]"
          :clearable="item.isClearable"
          :collapse-tags="item.isCollapseTags"
          :disabled="item.disabled && item.disabled(searchData)"
          :options="item.options"
          :placeholder="item.placeholder"
          :props="item.props"
          :show-all-levels="item.showAllLevels"
          :style="{width:item.width?item.width:'100%'}"
          @change="item.change &&item.change(that,searchData[item.prop])"
        />
        <el-date-picker
          v-if="item.type==='date'"
          v-model="searchData[item.prop]"
          :disabled="item.disable && item.disable(searchData[item.prop])"
          :placeholder="item.placeholder"
          :size="size || item.size"
          :style="{width:item.width}"
          :value-format="item.format"
          @change="item.change && item.change(that,searchData[item.prop])"
        />
        <!-- 日期时间 -->
        <el-date-picker
          v-if="item.type==='dateTime'"
          v-model="searchData[item.prop]"
          :default-time="item.default"
          :disabled="item.disable && item.disable(searchData[item.prop])"
          :format="item.format"
          :picker-options="item.pickerOptions"
          :placeholder="item.placeholder"
          :value-format="item.format"
          type="datetime"
          @change="item.change && item.change(searchData[item.prop])"
        />
        <!-- 区间 -->
        <el-date-picker
          v-if="item.type==='daterange'"
          v-model="searchData[item.prop]"
          :disabled="item.disable && item.disable(searchData)"
          :end-placeholder="item.endPlaceholder"
          :placeholder="item.placeholder"
          :range-separator="item.separator"
          :start-placeholder="item.startPlaceholder"
          :type="item.type"
          :value-format="item.format"
          @change="item.change && item.change(that,searchData[item.prop])"
        />
        <!-- 下拉框isMultiple（是否多选） -->
        <el-select
          v-if="item.type==='select'"
          v-model="searchData[item.prop]"
          :clearable="item.isClearable"
          :collapse-tags="item.isCollapseTags"
          :disabled="item.disable && item.disable(searchData[item.prop])"
          :multiple="item.isMultiple"
          :placeholder="item.placeholder"
          :size="size || item.size"
          :style="{width:item.width}"
          @change="item.change && item.change(that,searchData[item.prop])"
        >
          <div v-if="item.prop==='community'">
            <el-option v-for="op in communityData" :key="op.id" :label="op.parkName" :value="op.parkId" />
          </div>
          <div v-else>
            <el-option v-for="op in item.options" :key="op.value" :label="op.label" :value="op.value" />
          </div>
        </el-select>
      </el-form-item>
      <el-form-item v-for="item in searchHandle" :key="item.label">
        <el-button
          :icon="item.icon"
          :plain="item.plain"
          :size="item.size || size"
          :type="item.type || type"
          @click="item.handle(that)"
        >
          {{ item.label }}
        </el-button>
      </el-form-item>
    </el-form>
    <!--    <el-form v-if="isHandle" inline>-->
    <!--      <el-form-item v-for="item in searchHandle" :key="item.label">-->
    <!--        <el-button :type="item.type || type" :plain="item.plain" :size="item.size || size" :icon="item.icon" @click="item.handle(that)">-->
    <!--          {{ item.label }}-->
    <!--        </el-button>-->
    <!--      </el-form-item>-->
    <!--    </el-form>-->
  </div>
</template>

<script>
export default {
  name: 'HSearch',
  props: {
    that: {
      type: Object,
      default: this,
    },
    type: {
      type: String,
      default: '',
    },
    isHandle: {
      type: Boolean,
      default: true,
    },
    labelWidth: {
      type: String,
      default: '100px',
    },
    size: {
      type: String,
      default: 'medium',
    },
    searchForm: {
      type: Array,
      default: () => [],
    },
    searchHandle: {
      type: Array,
      default: () => [],
    },
    searchData: {
      type: Object,
      default: () => {
      },
    },
    searchRules: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      communityData: [],
    }
  },
}
</script>

<style lang="scss" scoped>
.ces-search {
  /deep/ .el-form-item--medium .el-form-item__label {
    background-color: #F7F9FC;
    border: 1px solid #D9D9D9;
    border-right: 0;
    border-radius: 4px 0 0 4px;
    line-height: 40px;
    padding-left: 12px;
  }

  /deep/ .el-input--medium .el-input__inner, .el-input--mini .el-input__inner {
    border-radius: 0 4px 4px 0;
  }
}

.el-select, .el-input, .el-date-editor.el-input {
  width: 200px !important;
}

.el-form-item__content .el-form-item, .el-form-item--medium {
  margin-right: 12px !important;
  margin-bottom: 12px !important;
  height: 32px !important;

}

.el-form-item--medium .el-form-item__content {
  line-height: 40px !important;
}

.el-button {
  height: 40px !important;
  border-radius: 4px !important;
  padding: 0 22px 0 22px !important;

}

.el-input--medium .el-input__inner {
  height: 32px !important;
  line-height: 32px !important;
  padding-left: 15px !important;
}

/deep/ .el-input--medium {
  font-size: 14px !important;
}

.ces-search {
  background-color: white;
  padding: 16px 16px 4px 16px;
  margin: 12px 12px 0px 12px;
  border-radius: 2px;
}

.el-dialog__body {

  .ces-search {
    padding: 0 16px;
    margin: 0 12px;
  }
}
</style>
