import Layout from '@/layout'
import { USER_TYPE } from '@/const/sys'
// 企业端 外部学员管理
const studentsManagementRouter = {
  path: '/students',
  component: Layout,
  redirect: 'noRedirect',
  meta: {
    title: '培训档案管理',
    icon: 'contractor',
    roles: [USER_TYPE.organization.val],
  },
  alwaysShow: true,
  children: [
    {
      path: '',
      component: () => import('@/views/company/students'),
      name: 'students',
      meta: { title: '学员管理', icon: null },
    },
    {
      path: 'add',
      component: () => import('@/views/company/students/add'),
      name: 'studentsAdd',
      hidden: true,
      meta: { title: '添加员工', icon: null, noCache: true, activeMenu: '/students' },
    },
    {
      path: 'detail',
      component: () => import('@/views/company/students/detail'),
      name: 'studentsDetail',
      hidden: true,
      meta: { title: '员工管理', icon: null, noCache: true, activeMenu: '/students' },
    },
  ],
}

export default studentsManagementRouter
