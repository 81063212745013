import request from '@/utils/request'

// 判断第三方用户权限
export function checkUserAuth(data) {
  return request({
    url: '/third/userAuth',
    method: 'post',
    data,
  })
}

export function logout() {
  return request({
    url: '/security-user-server/user/logout',
    method: 'post',
  })
}
